// helper function to concat a list of functions that return styles
import Tackons from "utils/tackons";

function StyleMaker(tackons, listOfStyles) {
  let style = {};
  if (tackons) {
    style = Tackons(tackons);
  }
  if (!Array.isArray(listOfStyles)) {
    console.error("You must pass an array of styles to this function");
    return listOfStyles;
  }

  const styleProps = listOfStyles.reduce((a, c) => {
    const [Obj] = Object.entries(c);
    const key = Obj[0];
    let value = Obj[1];
    if (typeof value !== "undefined") {
      if (typeof value !== "string") {
        value = value + "px";
      }
      const prop = { [key]: value };
      a.push(prop);
    }
    return a;
  }, []);

  return Object.assign(style, ...styleProps);
}

export default StyleMaker;
