import React, { useState, useEffect } from "react";
import Styles from "../carousel.module.scss";

interface IDotsProps {
  /** Ref for the carousel container */
  containerRef;
  /** Currently active page */
  selectedIndex: number;
  /** callback function when scrolling is complete */
  onChange?(value: any): void;
  /** Index for first visible item */
  setFirstVisibleItemIndex: React.Dispatch<React.SetStateAction<number>>;
}

const Dots = (props: IDotsProps) => {
  const {
    containerRef,
    selectedIndex,
    onChange,
    setFirstVisibleItemIndex,
  } = props;
  const [container, setContainer] = useState(
    containerRef && containerRef.current
  );

  useEffect(() => {
    if (containerRef) {
      setContainer(containerRef.current);
    }
  }, [containerRef]);

  if (!container) {
    return null;
  }

  const scroll = (
    container,
    selectedIndex,
    newIndex,
    onChange,
    setFirstVisibleItemIndex
  ) => {
    if (newIndex !== selectedIndex) {
      const itemWidth = container.children[0].offsetWidth;
      const leftPosition = newIndex * itemWidth;

      container.scrollTo({
        left: leftPosition,
        behavior: "smooth",
      });
      onChange(newIndex);
      setFirstVisibleItemIndex(newIndex);
    }
  };

  const dots = Array.from(container.children).map((_, index) => {
    const className = `${Styles.dot} ${
      index === selectedIndex ? Styles.selectedDot : ""
    }`;
    return (
      <li
        key={index}
        className={className}
        onClick={() =>
          scroll(
            container,
            selectedIndex,
            index,
            onChange,
            setFirstVisibleItemIndex
          )
        }
      ></li>
    );
  });

  return <>{dots}</>;
};

export default Dots;
