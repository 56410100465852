import React, { useState, useEffect } from "react";
import Icon from "../icon";
import Grid from "../grid";
import Banner from "../banner";
import Text from "../text";
import Spinner from "../spinner";
import Button from "../button";
import ReactSelect, { components } from "react-select";
import { useDebounce } from "../../hooks/useDebounce";
import Styles from "./gpSearch.module.scss";

interface IGPData {
  label?: string;
  value?: string;
  name: string;
  code: string;
  ccgDetail: {
    code: string;
    name: string;
    region: string;
  };
  ccg: string;
  address: {
    postcode: string;
    lineone?: string;
    linetwo?: string;
  };
  telephone: string;
  previousCcgs?: string[];
}

interface IRequestData {
  data: IGPData[];
  status?: "loading" | "success" | "error";
}

interface IProps {
  /**
   *  Unique indentifier that ties the label to the input
   */
  id: string;
  /**
   *  Request result data, if loading is not needed populate only data field
   */
  requestData: IRequestData;
  /**
   * Label for the search field
   */
  label?: string;
  /**
   * Visually hide the label (still visible to screenreaders)
   */
  labelHidden?: boolean;
  /**
   * Additional text to support the label
   */
  hint?: string;
  /**
   * Adds placeholder text to the search input
   */
  placeholder?: string;
  /**
   * May be added as a prop to toggle or the invalid state, or given a string to show an error message.
   */
  invalid?: boolean | string;
  /**
   * Shows selected GP with possibility to change
   */
  selectedGP?: IGPData;
  /**
   * Hides change button for selected gp
   */
  hideChangeButton?: boolean;
  /**
   * Hides CCG and GMP code for member view
   */
  memberView?: boolean;
  /**
   * Callback when input changed
   */
  onInputChange?: (term: string) => void;
  /**
   * Callback when selected GP changed
   */
  onValueChange?: (data: IGPData) => void
}

const GPSearch: React.FunctionComponent<IProps> = (props) => {
  // Props
  const {
    id,
    requestData,
    label,
    labelHidden,
    hint,
    placeholder,
    invalid,
    selectedGP,
    hideChangeButton,
    memberView,
    onInputChange,
    onValueChange
  } = props;
  const { data, status } = requestData;

  // State
  const [GP, setGP] = useState(selectedGP);
  const [searchTerm, setSearchTerm] = useState("");
  // Effects
  useEffect(() => {
    setGP(selectedGP);
  }, [selectedGP?.code]);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const noOptionsMessage = () => {
    if (status === "error") {
      return null;
    }
    return searchTerm.length < 3
      ? "Enter 3 or more characters"
      : "No results found.";
  };

  return (
    <>
      <label htmlFor={id} className={labelHidden ? Styles.labelHidden : Styles.label}>
        <span className={Styles.text}>{label}</span>
      </label>
      {GP ? (
        <GPInformation
          GP={GP}
          memberView={memberView}
          hideChangeButton={hideChangeButton}
          onReset={() => { setGP(null); onValueChange(null) }}
        />
      ) : (
        <>
          {hint && <div className={Styles.hint}>{hint}</div>}
          <ReactSelect
            id={id}
            placeholder={placeholder || ""}
            hint={hint}
            options={
              debouncedSearchTerm.length > 0 && data
                ? data.map((gp: IGPData) => ({
                  label: gp.name,
                  value: gp.code,
                  address: gp.address,
                  ccgDetail: gp.ccgDetail?.name
                    ? `${gp.ccgDetail?.name} [${gp.ccg}]`
                    : gp.ccg,
                  ccg: gp.ccg,
                  telephone: gp.telephone,
                  previousCcgs: gp.previousCcgs
                }))
                : []
            }
            isLoading={status === "loading" && searchTerm.length >= 3}
            noOptionsMessage={noOptionsMessage}
            onInputChange={(value: string) => { setSearchTerm(value); onInputChange(value); }}
            onChange={(selectedOption: IGPData) => { setGP(selectedOption); onValueChange(selectedOption) }}
            styles={customStyle(invalid)}
            components={{
              DropdownIndicator,
              IndicatorSeparator: null,
              Option,
              LoadingIndicator: null,
              LoadingMessage,
            }}
            closeMenuOnSelect
            isSearchable
            filterOption={false}
            aria-invalid={invalid && typeof invalid == "string" ? true : invalid}
          />
          {invalid && typeof invalid === "string" && (
            <div className={Styles.invalidText}>
              <Icon
                name="exclamation-circle"
                width="16"
                height="16"
                accessibilityTitle="Invalid"
                decorative
              />
              {invalid}
            </div>
          )}
          {status === "error" && (
            <Grid.Row>
              <Grid.Col xs={8}>
                <div className={Styles.errorWrapper}>
                  <Banner appearance="critical">
                    Something went wrong. Please try again later.
                  </Banner>
                </div>
              </Grid.Col>
            </Grid.Row>
          )}
        </>
      )}
    </>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="search" decorative />
    </components.DropdownIndicator>
  );
};

const fullAddress = (address) => Object.values(address).join(" ");

const Option = (props: any) => {
  const { label, data } = props;
  return (
    <div data-testid="surgeryoption">
      <components.Option {...props}>
        <Text strong>{label}</Text>
        <Text>{fullAddress(data.address)}</Text>
      </components.Option>
    </div>
  );
};

export const GPInformation = (
  {
    GP,
    memberView,
    hideChangeButton,
    onReset
  }: {
    GP: IGPData,
    memberView: boolean,
    hideChangeButton: boolean,
    onReset: () => void
  }) => {
  return (
    <div id="GP-info" className={Styles.container}>
      <Text strong>{GP.label || GP.address.lineone}</Text>
      <address><Text appearance="muted">{fullAddress(GP.address)}</Text></address>
      {memberView ? null : <>
        <Text appearance="muted">{`GMP Code: ${GP.value || GP.code}`}</Text>
        <Text appearance="muted">{`CCG: ${GP.ccg}`}</Text>
      </>}
      <Text appearance="muted">{`Phone: ${GP.telephone}`}</Text>
      {hideChangeButton ? null : (
        <Button
          appearance="link"
          size="small"
          outline
          onClick={onReset}
          tackons="mt1"
        >
          Change
        </Button>
      )}
    </div>
  );
};

const LoadingMessage = () => (
  <div className={Styles.loadingMessage}>
    <Spinner color="shade" size="small" />
    <Text appearance="muted" tackons="ml1">
      Searching GP Surgeries
    </Text>
  </div>
);

const customStyle = (invalid?: string | boolean) => ({
  control: (provided, state) => ({
    ...provided,
    borderColor: invalid ? "#de3618" : state.isFocused ? "#06679b" : "#c3ced6",
    boxShadow: state.isFocused ? "0 0 0 3px rgba(6,103,155,.2)" : "none",
    borderWidth: "2px",
    outline: "none",
    "&:hover": {
      cursor: "text",
      borderColor: invalid ? "#de3618" : state.isFocused ? "#06679b" : "#c3ced6",
    },
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    textAlign: "left",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "0",
  }),
});

GPSearch.displayName = "GP Search";

GPSearch.defaultProps = {
  label: "",
  onInputChange: () => { },
  onValueChange: () => { }
};

export default GPSearch;
