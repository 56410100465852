// -----------------------------------------------------------------------------
// DateField
// -----------------------------------------------------------------------------

//React
import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Icon from "components/icon";
import TextField from "components/textField";

//Utilities
import { ClassNameMaker, Tackons } from "utils";
import { hatefulWayToProgrammaticallyTriggerOnChangeEvent } from "../../hacks";

//Styles
import Styles from "./dateField.module.scss";

function formatDate({ day, month, year }) {
  return day || month || year
    ? `${year.padStart(4, "0")}-${month.padStart(2, "0")}-${day.padStart(
      2,
      "0"
    )}`
    : "";
}

function DateField(props) {
  const {
    hint,
    id,
    name,
    label,
    labelHidden,

    value,
    invalid,
    shortWidth,

    // State
    disabled, //
    readOnly,

    // Events
    onChange,

    tackons,

    minYear,
    maxYear,
  } = props;

  const getDateObj = (date) => {
    const mom = moment(date || [0, 0, 0]);

    if (mom.isValid()) {
      return {
        day: mom.date().toString(),
        month: (mom.month() + 1).toString(),
        year: (mom.year() || "").toString(),
      };
    } else {
      return {};
    }
  };

  const [dateObj, setDateObj] = useState({
    day: "",
    month: "",
    year: "",
    ...getDateObj(value),
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setDateObj({ ...dateObj, ...getDateObj(value) });
  }, [value]);

  const hiddenSide = useRef(null);

  const onPartChange = (part, e) => {
    const val = e.target.value;
    const updatedParts = { ...dateObj, [part]: val };
    const { day, month, year } = updatedParts;

    setDateObj({
      day,
      month,
      year,
    });

    hatefulWayToProgrammaticallyTriggerOnChangeEvent(
      hiddenSide,
      formatDate({ day, month, year })
    );
  };

  const [validDate, setValidity] = useState(true);

  useEffect(() => {
    const { day, month, year } = dateObj;
    const monthInt = parseInt(month, 10) - 1;
    if (day && month && year && !moment([year, monthInt, day]).isValid()) {
      setValidity(false);
    } else {
      setValidity(true);
    }
  }, [dateObj]);

  useEffect(() => {
    if (invalid) setValidity(false);
  }, [invalid]);

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    disabled && Styles["disabled"],
    !validDate && Styles["invalid"],
    labelHidden && Styles["labelHidden"],
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  const fieldGroupClassName = `${Styles.fieldGroup} ${shortWidth ? Styles.shortWidth : ""}`;

  //Output

  return (
    <fieldset id={id} className={className} style={style}>
      <legend className={Styles.label}>
        <span className={Styles.text}>{label}</span>
      </legend>
      {hint && <div className={Styles.hint}>{hint}</div>}
      <div className={fieldGroupClassName}>
        <div className={Styles.field}>
          <TextField
            id={`${id}-day`}
            label="Day"
            pattern="[0-9]{1,2}"
            type="number"
            min={1}
            max={31}
            value={dateObj.day.toString()}
            fieldset
            readOnly={readOnly}
            invalid={!validDate}
            disabled={disabled}
            onChange={(e) => onPartChange("day", e)}
          ></TextField>
        </div>
        <div className={Styles.field}>
          <TextField
            id={`${id}-month`}
            label="Month"
            pattern="[0-9]{1,2}"
            type="number"
            min={1}
            max={12}
            value={dateObj.month.toString()}
            fieldset
            readOnly={readOnly}
            invalid={!validDate}
            disabled={disabled}
            onChange={(e) => onPartChange("month", e)}
          ></TextField>
        </div>
        <div className={`${Styles.field} ${Styles.yearField}`}>
          <TextField
            id={`${id}-year`}
            label="Year"
            pattern="[0-9]{4}"
            type="number"
            value={dateObj.year.toString()}
            fieldset
            readOnly={readOnly}
            invalid={!validDate}
            disabled={disabled}
            min={minYear}
            max={maxYear}
            onChange={(e) => onPartChange("year", e)}
          ></TextField>
        </div>
      </div>
      <input
        type="text"
        style={{ display: "none" }}
        name={name}
        onChange={onChange}
        ref={hiddenSide}
        value={formatDate(dateObj)}
      />
      {invalid && typeof invalid === "string" && (
        <div className={Styles.invalidText}>
          <Icon
            name="exclamation-circle"
            width="16"
            height="16"
            decorative
            accessibilityTitle="Invalid"
          />
          {invalid}
        </div>
      )}
    </fieldset>
  );
}

DateField.displayName = "DateField";

DateField.propTypes = {
  /**
   * Additional text to support the label
   */
  hint: PropTypes.string,
  /**
   * Unique indentifier that ties the label to the input
   */
  id: PropTypes.string.isRequired,
  /**
   * Label for the TextField
   */
  label: PropTypes.string.isRequired,
  /**
   * Visually hide the label (still visible to screenreaders)
   */
  labelHidden: PropTypes.bool,
  /**
   * Used to identify form data after it has been submitted to the server
   */
  name: PropTypes.string,
  /**
   * Regex pattern for validation
   */
  pattern: PropTypes.string,
  /**
   * Adds placeholder text to the input
   */
  placeholder: PropTypes.string,
  /**
   * Set value programatically, should be recognisable by moment
   */
  value: PropTypes.any,
  /**
   * May be added as a prop to toggle or the invalid state, or given a string to show an error message.
   */
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Add disabled attribute to the input
   */
  disabled: PropTypes.bool,
  /**
   * Set the readonly attribute on the input
   */
  readOnly: PropTypes.bool,
  /**
   * Callback when changed, is passed an object including
   *  - moment
   *  - day
   *  - month
   *  - year
   */
  onChange: PropTypes.func,
  tackons: PropTypes.string,
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
};

DateField.defaultProps = {
  id: undefined,
  name: undefined,
  hint: undefined,
  invalid: false,
  label: undefined,
  labelHidden: false,
  options: undefined,
  placeholder: undefined,
  readOnly: false,
  required: false,
  disabled: false,
  onChange: () => { },
  tackons: undefined,
  minYear: 1900,
  maxYear: 2200,
};

export default DateField;
