import React, { useState, useCallback } from "react";
import { Range as RRange } from "react-range";

import { Tackons } from "utils";

import Thumb, { IThumbProps } from "./components/thumb";
import Track, { ITrackProps } from "./components/track";

import Styles from "./range.module.scss";

export interface IRangeProps {
  // The range start. Can be decimal or negative. Default is 0.
  min?: number;
  // The range end. Can be decimal or negative. Default is 100.
  max?: number;
  // The minimal distance between two values. Can be decimal. Default is 1.
  step?: number;
  // To define your track (root) element.
  renderThumb?: (props: IThumbProps) => React.ReactNode;
  // To define your track (root) element
  renderTrack?: (props: ITrackProps) => React.ReactNode;
  /**
   * An array of numbers. It controls the position of thumbs on the track.
   * values.length equals to the number of rendered thumbs.
   */
  values: number[];
  // Called when a thumb is moved, provides new values.
  onChange?: (values: number[]) => void;
  // When there are multiple thumbs on a single track, should they be allowed to overlap? Default is false.
  allowOverlap?: boolean;
  // show | hide Thumb tooltip
  showTooltip?: boolean;
  // tooltip value formatter (because of step)
  tooltipValueFormatter?: (value: number) => number;
  /**
   * See [track colors]{@link https://github.com/tajo/react-range/blob/master/src/utils.ts#L178} for more info
   */
  trackColors?: string[];

  tackons?: string;
}

const Range: React.FC<IRangeProps> = (props) => {
  const { min, max, values, showTooltip, tooltipValueFormatter } = props;
  const [state, setState] = useState(values);

  // get inline styles for utility styles
  const style = props.tackons && Tackons(props.tackons);

  const handleChange = useCallback(
    (values) => {
      setState(values);
      props.onChange(values);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setState, props.onChange]
  );

  if (values.some((value) => value < props.min || value > props.max)) {
    throw new Error(
      `All values should be in range min=${props.min} and max=${props.max}`
    );
  }

  return (
    <div className={Styles.container} style={style}>
      <RRange
        values={state}
        step={props.step}
        min={min}
        max={max}
        onChange={handleChange}
        renderTrack={(rtProps) =>
          props.renderTrack({
            ...rtProps,
            values: state,
            min,
            max,
            colors: props.trackColors,
          })
        }
        renderThumb={(rtProps) =>
          props.renderThumb({
            ...rtProps,
            showTooltip,
            tooltipValueFormatter,
          })
        }
        allowOverlap={props.allowOverlap}
      />
    </div>
  );
};

Range.displayName = "Range";

Range.defaultProps = {
  renderThumb: (props: IThumbProps) => <Thumb {...props} />,
  renderTrack: (props: ITrackProps) => <Track {...props} />,
  min: 0,
  max: 100,
  step: 1,
  onChange: (_values: number[]) => {},
  values: [],
  allowOverlap: false,
  showTooltip: true,
  tooltipValueFormatter: (value) => value,
  trackColors: ["#EDC10E", "#E5E5E5"],
};

export default Range;
