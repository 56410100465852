import React from "react";
import PropTypes from "prop-types";

// Styles
import Styles from "./text.module.scss";

// Utilities
import { ClassNameMaker, Tackons } from "utils";

/**
 *
 * `<Text/>` is a component is used to render text in a variety of styles based on our responsive typography base.
 */
function Text(props) {
  const {
    appearance,
    children,
    element,
    id,
    size,
    strong,
    weight,
    tackons,
    truncated
  } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    appearance && Styles[appearance],
    size && Styles[size],
    strong && Styles["strong"],
    weight && Styles[`weight-${weight}`],
    truncated && Styles["truncated"]
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return React.createElement(element ? element : "p", {
    className: className,
    children: children,
    id: id,
    style: style
  });
}

Text.propTypes = {
  /**
   * Choose from a range of presentation styles
   */
  appearance: PropTypes.oneOf(["muted", "negative", "positive"]),
  /**
   * Content to display inside the Text
   */
  children: PropTypes.node,
  /**
   * Container element to use as the render
   */
  element: PropTypes.oneOf(["p", "span", "div"]),
  /**
   * Unique indentifier to identify the element in the DOM
   */
  id: PropTypes.string,
  /**
   * Display size of the text
   */
  size: PropTypes.oneOf(["lead", "body", "caption", "small"]),
  /**
   * Give additional weight to the text (600)
   */
  strong: PropTypes.bool,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
  /**
   * Make this text single-line. If the line length consumes more than 100% of the container width any overflow will be hidden and replaced with an ellipsis
   */
  truncated: PropTypes.bool,
  /**
   * Add font-weight to the text
   */
  weight: PropTypes.oneOf(["200", "600", "700"])
};

Text.defaultProps = {
  appearance: undefined,
  children: undefined,
  element: undefined,
  id: undefined,
  size: undefined,
  strong: false,
  stronger: false,
  tackons: undefined,
  truncated: false
};

export default Text;