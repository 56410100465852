import React from "react";
import PropTypes from "prop-types";
import IconPaths from "components/icon/iconPaths.generated";

import Button from "components/button";
import Icon from "components/icon";
import Heading from "components/heading";
import Stack from "components/stack";

// Styles
import Styles from "../card.module.scss";

// Utilities
import { ClassNameMaker, Tackons } from "utils";

const IconsEnum = Object.keys(IconPaths);

function Header(props) {
  const { actions, icon, title, tackons } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([Styles.header]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);
  return (
    <header className={className} style={style}>
      <div className={Styles.title}>
        {icon && (
          <div className={Styles.icon}>
            <Icon name={icon} decorative />
          </div>
        )}
        <Heading element="h3">{title}</Heading>
      </div>
      {actions && (
        <div className={Styles.actions}>
          <Stack spacing="extraTight" justify="end">
            {actions.map((a, i) => {
              return (
                <Button appearance="tertiary" key={i} size="small" onClick={a.onClick}>
                  {a.label}
                </Button>
              );
            })}
          </Stack>
        </div>
      )}
    </header>
  );
}

Header.displayName = "Header";

Header.propTypes = {
  /**
   * Collection of buttons to be displayed alongside the title.
   */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  /**
   * Prefix the title with an icon
   */
  icon: PropTypes.oneOf(IconsEnum),
  /**
   * Title to display
   */
  title: PropTypes.string,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
};

Header.defaultProps = {
  icon: undefined,
  title: undefined,
  tackons: undefined,
};

export default Header;
