import React from "react";
import Styles from "./textRun.module.scss";

type TextRunProps = {
    readonly variant?: "paragraph" | "page-title";
}

const TextRun: React.FC<TextRunProps> = props => {
    const Container = props.variant === "page-title" ? "h1" : "p";
    return (
        <Container className={Styles[props.variant]}>
            {props.children}
        </Container>
    );
}

TextRun.displayName = "TextRun";
TextRun.defaultProps = {
    variant: "paragraph",
};

export default TextRun;
