import React, { useState } from "react";
import PropTypes from "prop-types";
import { usePopper } from "react-popper-2";
import { Placement } from "@popperjs/core";
import usePortal from "react-cool-portal";
import Styles from "./popover.module.scss";

// TODO: trigger should have clickable props
interface IPopoverProps {
  /**
   * Choose whether to display a connecting arrow to join the trigger to the popover content.
   */
  arrow?: boolean;
  /**
   * Unique name to identify this element in the DOM.
   */
  id?: string;
  /**
   * Trigger should be a component or DOM element; onclick events will be automatically attached to the item.
   */
  trigger: React.ReactElement;
  /**
   * Pass the content to be displayed in the popover as child content.
   */
  children: React.ReactElement;
  /**
   * Placement allows you to set a positioning preference for the popover. Positioning will be automatically adjusted if the viewport prevents the popover from being visible. More information [here](https://popper.js.org/).
   */
  placement: Placement;
  /**
   * Pass an array of two numbers representing the skid (offset along the placement axis) and distance [offset values](https://popper.js.org/docs/v2/modifiers/offset/).
   */
  offset: [number, number];
}
/** Popover is a utility component for placing content in frame which is displayed when a trigger item is clicked. It uses [React Popper](https://popper.js.org/react-popper/) a wrapper on Popper JS to handle core functionality, and the [React Cool Portal](https://github.com/wellyshen/react-cool-portal) hook to project the content into a root DOM location.  */
function Popover(props: IPopoverProps) {
  const { arrow, id, trigger, children, placement, offset } = props;

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const config = [];

  if (offset && offset.length === 2) {
    config.push({
      name: "offset",
      enabled: true,
      options: {
        offset: offset,
      },
    });
  }

  if (arrow) {
    config.push({
      name: "arrow",
      enabled: true,
      options: { element: arrowElement },
    });
  }

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: config,
    strategy: "fixed",
  });

  const { Portal, isShow, toggle } = usePortal({
    containerId: "popover",
    defaultShow: false,
  });

  const triggerEl = React.cloneElement(trigger, {
    id: `${id}-button`,
    onClick: toggle,
    "aria-expanded": isShow,
    "aria-controls": `${id}-popover`,
  });

  return (
    <div className={Styles.container} id={id}>
      <div ref={setReferenceElement} className={Styles.triggerWrapper}>
        {triggerEl}
      </div>
      {isShow && (
        <Portal>
          <div
            ref={setPopperElement}
            className={Styles.popover}
            style={styles.popper}
            id={`${id}-popover`}
            {...attributes.popper}
          >
            {children}
            {arrow && (
              <div
                ref={setArrowElement}
                className={Styles.arrow}
                style={styles.arrow}
              />
            )}
          </div>
        </Portal>
      )}
    </div>
  );
}

Popover.propTypes = {
  id: PropTypes.string.isRequired,
  placement: PropTypes.oneOf([
    "auto",
    "auto-start",
    "auto-end",
    "top",
    "top-start",
    "top-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "right",
    "right-start",
    "right-end",
    "left",
    "left-start",
    "left-end",
  ]),
  offset: PropTypes.array,
  trigger: PropTypes.node.isRequired,
};

Popover.defaultProps = {
  arrow: false,
  placement: "bottom-start",
  offset: [0, 1],
};
export default Popover;
