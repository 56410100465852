import React from "react";
import PropTypes from "prop-types";
import IconPaths from "components/icon/iconPaths.generated";

import Header from "./components/header";
import Section from "./components/section";
import Progress from "../progress";

// Styles
import Styles from "./card.module.scss";

// Utilities
import { ClassNameMaker, Tackons } from "utils";

const IconsEnum = Object.keys(IconPaths);
/**
 * Cards are used to group information and tasks together to make the interface easier to read for the user.
 *
 */
function Card(props) {
  const {
    id,
    headerActions,
    children,
    muted,
    sectioned,
    header,
    progress,
    tackons,
  } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    muted && Styles["muted"],
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return (
    <div id={id} className={className} style={style}>
      {header && (
        <Card.Header
          title={typeof header == "object" ? header.title : header}
          icon={typeof header == "object" ? header.icon : undefined}
          actions={headerActions}
        />
      )}

      {sectioned ? (
        children.length > 1 ? (
          children.map((c, i) => {
            return <Card.Section key={i}>{React.cloneElement(c)}</Card.Section>;
          })
        ) : (
          <Card.Section>{children}</Card.Section>
        )
      ) : (
        <>{children}</>
      )}

      {React.isValidElement(progress) ? (
        progress
      ) : progress ? (
        <Progress {...progress} />
      ) : (
        <></>
      )}
    </div>
  );
}

Card.displayName = "Card";
Card.Header = Header;
Card.Section = Section;
Card.Progress = Progress;

Card.propTypes = {
  /**
   * Content to display inside the card
   */
  children: PropTypes.node,
  /**
   * Unique label to identify this element in the dom
   */
  id: PropTypes.string,
  /**
   * Pass the name of the icon, or pass an object containing
   * icon name and position.
   */
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.exact({
      title: PropTypes.string.isRequired,
      icon: PropTypes.oneOf(IconsEnum),
    }),
  ]),
  /**
   * Collection of actions to be displayed alongside the title.
   */
  headerActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  /**
   * Automatically wrap immediate child elements of {children} in a Card.Section component
   */

  sectioned: PropTypes.bool,
  /**
   * Make the background of the card gray
   */
  muted: PropTypes.bool,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,

  progress: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.exact({
      color: PropTypes.string,
      height: PropTypes.string,
      value: PropTypes.number,
    }),
  ]),
};

Card.defaultProps = {
  children: undefined,
  id: undefined,
  header: undefined,
  headerActions: undefined,
  sectioned: false,
  tackons: undefined,
};

export default Card;
