import React from "react";
import PropTypes from "prop-types";

// Styles
import Styles from "../collapsible.module.scss";

// Utilities
import { ClassNameMaker, Tackons } from "utils";

function Panel(props) {
  const { children, height, id, forwardedRef, isOpen, tackons } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.panel,
    isOpen && Styles["panelOpen"]
  ]);

  // get inline styles for utility styles
  const tackonsStyles = tackons && Tackons(tackons);
  const style = { ...tackonsStyles, maxHeight: `${height}` };
  // console.log(height);

  return (
    <div
      aria-hidden={!isOpen}
      className={className}
      style={style}
      ref={forwardedRef}
      id={id}
    >
      {children}
    </div>
  );
}

Panel.displayName = "Panel";

Panel.propTypes = {
  /**
   * Content to display inside the panel
   */
  children: PropTypes.node,
  /**
   * useRef reference element to measure height
   */
  forwardedRef: PropTypes.object,
  /**
   * Height value passed by the HOC to animate maxHeight
   */
  height: PropTypes.string,
  /**
   * Unique identifier for element in DOM
   */
  id: PropTypes.string,
  /**
   * Panel open state
   */
  isOpen: PropTypes.bool,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string
};

Panel.defaultProps = {
  children: undefined,
  forwardedRef: undefined,
  height: undefined,
  isOpen: false,
  tackons: undefined
};

export default Panel;
