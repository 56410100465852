// -----------------------------------------------------------------------------
// TextField
// -----------------------------------------------------------------------------

// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Icon from "components/icon";

// Utilities
import { ClassNameMaker, StyleMaker } from "utils";

// Styles
import Styles from "./textField.module.scss";

const VISIBLE_PASSWORD_TYPE = "visible-password";
const PASSWORD_TYPE = "password";
const SEARCH_TYPE = "search";

function TextField(props) {
  const {
    autoComplete,
    autoFocus,
    disabled, //
    fieldset,
    forwardRef,
    hint,
    id,
    invalid,
    label,
    labelHidden,
    maxLength,
    multiline,
    max,
    maxWidth,
    min,
    minWidth,
    name,
    onBlur,
    onChange,
    onFocus,
    onKeyPress,
    pattern,
    placeholder,
    required,
    readOnly,
    spellCheck,
    step,
    tackons,
    type,
    value,
  } = props;

  // Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    fieldset && Styles.fieldset,
    disabled && Styles.disabled,
    invalid && Styles.invalid,
    labelHidden && Styles.labelHidden,
    Styles.iconContainer,
    Styles.inputContainer,
  ]);

  // minimum number of rows in a multiline text intpu
  const minRows = 3;

  // get inline styles for utility styles
  let style = StyleMaker(tackons, [
    { minWidth: minWidth },
    { maxWidth: maxWidth },
  ]);

  const [currentValue, setCurrentValue] = useState(value);
  const [currentType, setCurrentType] = useState(type);
  const [showSearchIcon, setShowSearchIcon] = useState(true);

  useEffect(() => {
    setCurrentValue(value);
    setCurrentType(type);
  }, [value, type]);

  const handleChange = (e) => {
    const newValue = e.target.value;

    setCurrentValue(newValue);
    onChange(e);
  };

  const autoCompleteStatus = () => {
    if (autoComplete === undefined) {
      return autoComplete;
    }
    if (autoComplete === true) {
      return "on";
    }
    if (autoComplete === false) {
      return "off";
    }
    return autoComplete;
  };

  const commonAttributes = {
    "aria-invalid": invalid && typeof invalid === "string" ? true : invalid,
    className: Styles.input,
    disabled,
    id,
    autoComplete: autoCompleteStatus(),
    autoFocus,
    name,
    ref: forwardRef,
    onFocus,
    onBlur,
    onChange: handleChange,
    onKeyPress,
    pattern,
    placeholder,
    readOnly,
    spellCheck,
    type: currentType,
    value: currentValue,
  };

  const multiLineAttributes = {
    pattern: undefined,
    type: undefined,
    style:
      multiline && typeof multiline === "number"
        ? { height: `${multiline * 24 + 16}px` }
        : undefined,
    rows:
      multiline && typeof multiline === "number"
        ? multiline
        : multiline && typeof multiline === "boolean"
          ? minRows
          : undefined,
  };

  const numberAttributes = {
    max,
    min,
    step,
  };

  const textAttributes = {
    maxLength,
  };

  const passwordAttributes = {
    spellcheck: undefined,
  };

  const searchAttributes = {
    onBlur: (e) => {
      if (!currentValue) {
        setShowSearchIcon(true);
      }
      onBlur(e);
    },
    onFocus: (e) => {
      setShowSearchIcon(false);
      onFocus(e);
    },
  };

  const mapTypeToAttributes = () => {
    const typesToAttributes = {
      text: textAttributes,
      number: numberAttributes,
      multiline: multiLineAttributes,
      password: passwordAttributes,
      search: searchAttributes,
      default: {},
    };
    return typesToAttributes[currentType] || typesToAttributes.default;
  };

  const input = React.createElement(multiline ? "textarea" : "input", {
    ...commonAttributes,
    ...mapTypeToAttributes(),
  });

  const InputIcon = () => {
    if (currentType === PASSWORD_TYPE) {
      return (
        <div
          className={Styles.iconContainer}
          onClick={() => setCurrentType(VISIBLE_PASSWORD_TYPE)}
        >
          <Icon name="hide" decorative accessibilityTitle="hide password"></Icon>
        </div>
      );
    } else if (currentType === VISIBLE_PASSWORD_TYPE) {
      return (
        <div
          className={Styles.iconContainer}
          onClick={() => setCurrentType(PASSWORD_TYPE)}
        >
          <Icon name="show" decorative accessibilityTitle="show password"></Icon>
        </div>
      );
    }
    if (currentType === SEARCH_TYPE && showSearchIcon) {
      return (
        <div className={Styles.noClickIcon}>
          <Icon name="search" decorative accessibilityTitle="search" />
        </div>
      );
    }
    return null;
  };

  // Output

  return (
    <div className={className} style={style}>
      <label htmlFor={id} className={Styles.label}>
        <span className={Styles.text}>{label}</span>
        {required ? <span className={Styles.required}>*</span> : <></>}
      </label>
      {hint && <div className={Styles.hint}>{hint}</div>}
      <div className={Styles.inputContainer}>
        {input}
        <InputIcon />
      </div>
      {invalid && typeof invalid === "string" && (
        <div className={Styles.invalidText}>
          <Icon
            name="exclamation-circle"
            width="16"
            height="16"
            accessibilityTitle="Invalid"
            decorative
          />
          {invalid}
        </div>
      )}
    </div>
  );
}

TextField.displayName = "TextField";

TextField.propTypes = {
  /**
   * Enable the browser to display autocomplete entried
   * Set the attribute alone, or use "on" and "off"
   */
  autoComplete: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Add disabled attribute to the input
   */
  disabled: PropTypes.bool,
  /**
   * TextFields that are part of a group e.g. DateField modify
   * the presentation of the label to allow for a parent label
   */
  fieldset: PropTypes.bool,
  /**
   * React ref for identifying the element
   */
  forwardRef: PropTypes.object,
  /**
   * Additional text to support the label
   */
  hint: PropTypes.string,
  /**
   * Unique indentifier that ties the label to the input
   */
  id: PropTypes.string.isRequired,
  /**
   * May be added as a prop to toggle or the invalid state, or given a string to show an error message.
   */
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Label for the TextField
   */
  label: PropTypes.string.isRequired,
  /**
   * Visually hide the label (still visible to screenreaders)
   */
  labelHidden: PropTypes.bool,
  /**
   * Maximum for number type inputs
   */
  max: PropTypes.number,
  /**
   * Constrain the maximum width of the cell. Pass a positive integer to set a maximum width in pixels.
   * Pass a string (e.g. maxWidth="10%") to use other units.
   */
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Set a minimum width for the cell. Pass a positive integer to set a minimum width in pixels.
   * Pass a string (e.g. minWidth="2rem") to use other units.
   */
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Minimum for number type inputs
   */
  min: PropTypes.number,
  /**
   * Set as attribute alone to render a `<textarea>` element with 3 lines
   * Set as a number to render with a specific number of lines
   */
  multiline: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  /**
   * Used to identify form data after it has been submitted to the server
   */
  name: PropTypes.string,
  /**
   * Callback on blur
   */
  onBlur: PropTypes.func,
  /**
   * Callback when changed
   */
  onChange: PropTypes.func,
  /**
   * Callback when key pressed
   */
  onKeyPress: PropTypes.func,

  /**
   * Callback when focussed
   */
  onFocus: PropTypes.func,
  /**
   * Regex pattern for validation
   */
  pattern: PropTypes.string,
  /**
   * Adds placeholder text to the input
   */
  placeholder: PropTypes.string,
  /**
   * Set the readonly attribute on the input
   */
  readOnly: PropTypes.bool,
  /**
   * Shows asterisk next to label if true
   */
  required: PropTypes.bool,
  /**
   * Enable the spellcheck attribute on the input (not supported on password inputs)
   */
  spellCheck: PropTypes.bool,
  /**
   * Step for number type inputs
   */
  step: PropTypes.string,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
  /**
   * Input type - not rendered when multiline is true
   */
  type: PropTypes.oneOf([
    "text",
    "email",
    "number",
    "password",
    "search",
    "url",
    "date",
    "time",
    "multiline",
    "visible-password",
  ]),
  /**
   * Set value programatically
   */
  value: PropTypes.string,
  /** Indicates whether the input should automatically receive the focus. */
  autoFocus: PropTypes.bool,
};

TextField.defaultProps = {
  autoComplete: undefined,
  disabled: false,
  fieldset: false,
  forwardRef: undefined,
  hint: undefined,
  id: undefined,
  invalid: undefined,
  label: undefined,
  labelHidden: false,
  max: undefined,
  maxWidth: undefined,
  maxLength: undefined,
  min: undefined,
  minWidth: undefined,
  multiline: undefined,
  name: undefined,
  onBlur: () => { },
  onChange: () => { },
  onFocus: () => { },
  onKeyPress: () => { },
  pattern: undefined,
  placeholder: undefined,
  readOnly: false,
  spellcheck: false,
  tackons: undefined,
  value: "",
  type: "text",
};

export default TextField;
