// -----------------------------------------------------------------------------
// TimePicker
// -----------------------------------------------------------------------------

// React
import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import Card from "../card";
import Icon from "../icon";
import ReactSelect, { components } from "react-select";

// Styles
import Styles from "./timePicker.module.scss";

type TimePickerProps = {
  disabled?: boolean;
  id: string;
  initialValue?: string;
  invalid?: boolean | string;
  label?: string;
  labelHidden?: boolean;
  min?: any;
  max?: any;
  onChange?: any;
  placeholder?: string;
  timeDifference?: any;
};

const TimePicker = forwardRef((props: TimePickerProps, ref?: any) => {
  const {
    disabled = false,
    id,
    initialValue,
    invalid,
    min,
    max,
    onChange,
    placeholder,
    timeDifference,
  } = props;
  const [selectedTime, setSelectedTime] = useState(initialValue);
  const [key, updateKey] = useState(0);

  const [inputTime, setInputText] = useState("");
  const options = generateTimeslots(min, max, timeDifference);

  const applyTime = (time) => {
    setTime(time);
    if ((time || selectedTime) && onChange) {
      onChange({
        target: {
          value: time || selectedTime,
        },
      });
    }
  };

  const setTime = (time) => {
    setSelectedTime(time);
    setInputText(time);
    updateKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    setInputText(selectedTime);
  }, [selectedTime]);

  useImperativeHandle(ref, () => ({
    updateValue(value) {
      setSelectedTime(value);
    },
  }));

  const styles = {
    container: (_, state) => ({
      borderColor: invalid
        ? "#de3618"
        : state.isFocused
          ? "#06679b"
          : "#c3ced6",
      boxShadow: state.isFocused ? "0 0 0 3px rgba(6, 103, 155, 0.2)" : "none",
    }),
    control: () => ({
      border: "none",
    }),
    indicatorsContainer: () => ({
      height: "0",
    }),
    input: () => ({
      fontFamily: "inherit",
    }),
  };

  return (
    <div className={Styles.pickerWrapper}>
      <ReactSelect
        key={id + key}
        styles={styles}
        isMulti={false}
        options={options}
        inputValue={inputTime}
        isDisabled={disabled}
        onChange={(value) => setSelectedTime(value)}
        onMenuClose={() => setTime(selectedTime)}
        onInputChange={(value) => setInputText(value)}
        components={{
          DropdownIndicator,
          Menu: (menuProps) => (
            <Menu
              id={id}
              selectedTime={selectedTime}
              options={options}
              inputTime={inputTime}
              menuProps={menuProps}
              setTime={setTime}
              applyTime={applyTime}
              setSelectedTime={setSelectedTime}
            />
          ),
          IndicatorSeparator: () => null,
        }}
        placeholder={placeholder}
        isSearchable={true}
      />
      {invalid && typeof invalid === "string" && (
        <div className={Styles.invalidText}>
          <Icon
            name="exclamation-circle"
            width="16"
            height="16"
            accessibilityTitle="Invalid"
            decorative
          />
          {invalid}
        </div>
      )}
    </div>
  );
});

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon
        name="wait"
        height="20"
        width="20"
        accessibilityTitle="select time"
        decorative
      />
    </components.DropdownIndicator>
  );
};

function Menu(props) {
  const {
    id,
    options,
    selectedTime,
    setSelectedTime,
    setTime,
    applyTime,
    menuProps,
    inputTime,
  } = props;

  const panelRef = useRef();
  const selectedTimeIndex = options.findIndex((option) =>
    option.includes(selectedTime)
  );

  const [highlighted, setHighlighted] = useState(
    selectedTimeIndex > -1 ? selectedTimeIndex : 48
  );

  useEffect(() => {
    highlightTime(highlighted, panelRef, setHighlighted);
  }, [selectedTime]);

  useEffect(() => {
    if (inputTime) {
      const index = options.findIndex((option) => option.includes(inputTime));
      highlightTime(index, panelRef, setHighlighted);
    }
  }, [inputTime]);

  const apply = (time) => {
    menuProps.selectOption(time);
    applyTime(time);
  };

  const keyDownHandler = (event) => {
    switch (event.key) {
      case "ArrowDown": {
        onArrowDown(highlighted, options, setSelectedTime);
        event.preventDefault();
        break;
      }
      case "ArrowUp": {
        onArrowUp(highlighted, options, setSelectedTime);
        event.preventDefault();
        break;
      }
      case "Escape":
      case "Tab": {
        setTime(selectedTime);
        break;
      }
      case "Enter": {
        const value = selectedTime || highlighted.slice(0, 5);
        apply(value);
        event.preventDefault();
        break;
      }
      default:
        return null;
    }
  };

  useEffect(() => {
    const listener = (event) => keyDownHandler(event);
    document.addEventListener("keydown", listener);

    return () => document.removeEventListener("keydown", listener);
  }, [keyDownHandler]);

  return (
    <components.Menu {...menuProps} maxMenuHeight={200}>
      <div ref={panelRef} className={Styles.panel}>
        <Card>
          <ul>
            {options.map((time, i) => (
              <li
                data-testid={`${id}-time-${time}`}
                data-time={time}
                aria-selected={i === highlighted ? "true" : "false"}
                key={time}
                onMouseEnter={() => setHighlighted(i)}
                onClick={() => {
                  apply(time.slice(0, 5));
                }}
              >
                {time}
              </li>
            ))}
          </ul>
        </Card>
      </div>
    </components.Menu>
  );
}

function generateTimeslots(min, max, timeDifference) {
  const minHour = min ? min.slice(0, 2) : 0;
  const maxHour = max ? max.slice(0, 2) : 24;
  const hours = generateHours().slice(minHour, +maxHour + 1);

  const minutes = ["00", "15", "30", "45"];

  return hours
    .map((hour) =>
      minutes.map((minute) => {
        let time = `${hour}:${minute}`;

        if (
          (min && time.localeCompare(min) < 0) ||
          (max && time.localeCompare(max) > 0)
        ) {
          return null;
        }

        if (timeDifference) {
          time += ` (${getPeriod(time, timeDifference)})`;
        }

        return time;
      })
    )
    .flat()
    .filter((time) => time);
}

function generateHours() {
  return new Array(24)
    .fill(0)
    .map((_, index) => index.toString().padStart(2, "0"));
}

function getPeriod(timeA, timeB) {
  const minsDiff = Math.abs(getMins(timeA) - getMins(timeB));

  const hoursPeriod = Math.floor(minsDiff / 60);
  const minsPeriod = minsDiff - hoursPeriod * 60;

  if (!hoursPeriod) {
    return `${minsPeriod}min`;
  }

  if (!minsPeriod) {
    return `${hoursPeriod}hr`;
  }

  return `${hoursPeriod}hr ${minsPeriod}min`;
}

function getMins(time) {
  return time.slice(0, 2) * 60 + parseInt(time.slice(3));
}

function onArrowDown(highlighted, options, setSelectedTime) {
  if (highlighted + 1 < options.length) {
    setSelectedTime(options[highlighted + 1].slice(0, 5));
  }
}

function onArrowUp(highlighted, options, setSelectedTime) {
  if (highlighted - 1 >= 0) {
    setSelectedTime(options[highlighted - 1].slice(0, 5));
  }
}

function highlightTime(index, panelRef, setHighlighted) {
  const listElement = panelRef.current.getElementsByTagName("ul")[0];
  const itemHeight = 35;

  setHighlighted(index);
  // it was added to avoid `scrollTo is not a function error` in tests
  if (listElement.scrollTo) {
    listElement.scrollTo({
      top: (index - 2) * itemHeight,
    });
  }
}

TimePicker.displayName = "TimePicker";

TimePicker.defaultProps = {
  disabled: false,
  id: undefined,
  initialValue: undefined,
  invalid: undefined,
  label: undefined,
  labelHidden: false,
  min: undefined,
  max: undefined,
  onChange: () => { },
  placeholder: undefined,
  timeDifference: undefined,
};

export default TimePicker;
