import React from "react";
import PropTypes from "prop-types";
import { between } from "airbnb-prop-types";
import { breakpointKeys } from "enums";

// Styles
import Styles from "./col.module.scss";

import tokens from "styles/tokens/tokens.ts.scss";

//Utilities
import { ClassNameMaker, Tackons } from "utils";

const colTotal = tokens.gridColumns;
const breakpoints = Object.values(breakpointKeys);

// type Col = React.FunctionComponent;

function Col(props) {
  const { children, id, tagName, tackons } = props;
  const breakpointClasses = [];
  const offsetClasses = [];
  const orderClasses = [];

  breakpoints.forEach((b) => {
    let colCount = typeof props[b] == "number" ? props[b] : "auto";
    let colOffset = props[`${b}-offset`];
    let colOrder =
      typeof props[`${b}-order`] == "number" ? props[`${b}-order`] : "initial";
    // column count props
    props[b] && breakpointClasses.push(Styles[`${b}-${colCount}`]);
    // column offset props
    props[`${b}-offset`] &&
      offsetClasses.push(Styles[`${b}-offset-${colOffset}`]);
    // column order props
    props[`${b}-order`] && orderClasses.push(Styles[`${b}-order-${colOrder}`]);
  });

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    ...breakpointClasses,
    ...offsetClasses,
    ...orderClasses,
    Styles.col,
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return React.createElement(
    tagName || "div",
    {
      className: className,
      style: style,
      id: id,
    },
    children
  );
}

const breakpointProps = breakpoints.reduce(function (obj, b) {
  obj[b] = PropTypes.oneOfType([
    PropTypes.bool,
    between({ gte: 1, lte: parseInt(colTotal) }),
  ]);
  return obj;
}, {});

const breakPointDefaults = breakpoints.reduce(function (obj, b) {
  obj[b] = undefined;
  return obj;
}, {});

const offsetProps = breakpoints.reduce(function (obj, b) {
  obj[`${b}-offset`] = between({ gte: 1, lte: parseInt(colTotal, 10) - 1 });
  return obj;
}, {});

const offsetDefaults = breakpoints.reduce(function (obj, b) {
  obj[`${b}-offset`] = undefined;
  return obj;
}, {});

const orderProps = breakpoints.reduce(function (obj, b) {
  obj[`${b}-order`] = PropTypes.oneOfType([
    PropTypes.bool,
    between({ gte: parseInt(colTotal, 10) * -1, lte: parseInt(colTotal, 10) }),
  ]);
  return obj;
}, {});

const orderDefaults = breakpoints.reduce(function (obj, b) {
  obj[`${b}-order`] = undefined;
  return obj;
}, {});

Col.propTypes = {
  ...breakpointProps,
  ...offsetProps,
  ...orderProps,

  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  /**
   * Unique indentifier in the DOM
   */
  id: PropTypes.string,
  /**
   * Content to display.
   */
  children: PropTypes.node,
  /*
   * Which element should be used to render the column
   */
  tagName: PropTypes.string,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
};
Col.defaultProps = {
  ...breakPointDefaults,
  ...offsetDefaults,
  ...orderDefaults,
  tagName: "div",
  children: undefined,
  id: undefined,
  tackons: undefined,
};

export default Col;
