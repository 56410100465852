// import styles from seoerate files
import { debug, debug16, solid8, solid16 } from "../../styles/debug";

const globalSpacingUnitMultiplier = 0.5;

const orientation = ["", "Top", "Right", "Left", "Bottom"];

const multiples = [0, 1, 2, 3, 4, 5];

const setTackOnKey = (attribute, orientation, multiple) => {
  // construct key from attribute and orientation
  const firstLetterFromAttribute = attribute.charAt(0);
  const firstLetterFromOrientation = orientation
    ? orientation.charAt(0).toLowerCase()
    : "";
  const multipleString = multiple.toString();

  return firstLetterFromAttribute + firstLetterFromOrientation + multipleString;
};

// reducer = (accumulator, currentValue) => accumulator + currentValue;

const reducer = (a, c) => ({ ...a, ...c });
// example to group similar styles
const createTackOnArray = (attribute, orientation, spacingMultiples) =>
  attribute
    .map(attr =>
      orientation
        .map(o =>
          spacingMultiples
            .map(m => ({
              [setTackOnKey(attr, o, m)]: {
                [attr + o]: `${m * globalSpacingUnitMultiplier}rem`
              }
            }))
            .reduce(reducer, {})
        )
        .reduce(reducer, {})
    )
    .reduce(reducer, {});
const tackOnArray = ["padding", "margin"];
const generatedTackOns = createTackOnArray(tackOnArray, orientation, multiples);

const styleSet = {
  debug: debug,
  debug16: debug16,
  solid8: solid8,
  solid16: solid16,
  ...generatedTackOns
};

function Tackons(utilityString) {
  if (typeof utilityString !== "string") {
    console.warn("Tackon must recieve a string");
    return;
  }

  const style = utilityString.split(" ").reduce((a, x) => {
    // find the style in the set or empty
    const s = styleSet[x] || {};
    return Object.assign(a, s);
  }, {});

  return style;
}

export default Tackons;
