// -----------------------------------------------------------------------------
// Stack
// -----------------------------------------------------------------------------

//React
import React from "react";
import PropTypes from "prop-types";
import { childrenOfType } from "airbnb-prop-types";

import Select from "components/select";
import ChoiceList from "components/choiceList";
import TextField from "components/textField";
import Checkbox from "components/checkbox";
import Stack from "components/stack";

//Utilities
import { ClassNameMaker, Tackons } from "utils";

//Styles
import Styles from "./formLayout.module.scss";

function FormLayout(props) {
  const { children, id, spacing, tackons } = props;
  //Variables

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    spacing && Styles[`spacing-${spacing}`]
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  //Output

  return (
    <div id={id} className={className} style={style}>
      {children.map((c, i) => {
        return (
          <div className={Styles.item} key={i}>
            {React.cloneElement(c)}
          </div>
        );
      })}
    </div>
  );
}

FormLayout.displayName = "FormLayout";

const validComponents = [ChoiceList, Checkbox, TextField, Select, Stack];

FormLayout.propTypes = {
  /**
   * Content to display inside the layout - only allows valid form elements
   */
  children: childrenOfType(...validComponents),
  /**
   * Unique indentifier that ties the label to the input
   */
  id: PropTypes.string,
  /**
   * Each child element is wrapped in container with margins to space the items.
   * Spacing controls the size of that margin
   */
  spacing: PropTypes.oneOf([
    "extraTight",
    "tight",
    "default",
    "loose",
    "extraLoose",
    "none"
  ]),
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string
};

FormLayout.defaultProps = {
  children: undefined,
  id: undefined,
  spacing: "default",
  tackons: undefined
};

export default FormLayout;
