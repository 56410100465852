// -----------------------------------------------------------------------------
// Country Picker
// -----------------------------------------------------------------------------

// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { countries } from "countries-list";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

import Icon from "components/icon";

// Utilities
import { StyleMaker } from "utils";

// Styles
import Styles from "./countryPicker.scss";

// Override dropdown component to use standard Icon
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon name="caret-down" accessibilityTitle="Select a country" />
    </components.DropdownIndicator>
  );
};

const countryKeys = Object.keys(countries);

/**
 *
 * Country picker displays a list of countries in a customised dropdown based on [react-select](https://react-select.com/) to provide additional features such as text search.
 */
function CountryPicker(props) {
  const {
    disabled,
    featured,
    id,
    isSearchable,
    maxWidth,
    minWidth,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    showFlags,
    tackons,
    value,
  } = props;

  // Convert tackons into online styles
  let style = StyleMaker(tackons, [
    { minWidth: minWidth },
    { maxWidth: maxWidth },
  ]);

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = (selectedOption) => {
    setCurrentValue(selectedOption.value);
    onChange(selectedOption.value);
  };

  // Shape the data from 'countries-list' package into the right format for react-select

  const countryList = countryKeys.map((o) => ({
    value: o,
    label: showFlags
      ? countries[o].emoji + " " + countries[o].name
      : countries[o].name,
  }));

  // default sort is by ISO country code rather than country name

  let countryListSorted = countryList.sort((a, b) => {
    const x = a.label.toUpperCase();
    const y = b.label.toUpperCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  });

  let featuredList = [];
  let countryListFiltered = countryListSorted;

  // Remove featured countries from the main country list

  if (featured) {
    featuredList = Object.values(countryList).filter((country) => {
      return featured.includes(country.value);
    });
    countryListFiltered = countryListSorted.filter((country) => {
      return !featured.includes(country.value);
    });
  }

  const options = [
    { options: [...featuredList] },
    { options: [...countryListFiltered] },
  ];

  // React select expects the entire object to set a value - we only expect the value as a string.
  const getValue = (opts, val) => opts.find((o) => o.value === val);

  return (
    <div id={id} style={style}>
      <ReactSelect
        options={options}
        isMulti={false}
        value={getValue(countryList, currentValue)}
        isDisabled={disabled}
        styles={customStyles}
        components={{ DropdownIndicator }}
        placeholder={placeholder}
        isSearchable={isSearchable}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
}

const customStyles = {
  control: (provided, state) => ({
    alignItems: "center",
    backgroundColor: "white",
    borderWidth: "2px",
    borderRadius: Styles.borderRadius,
    borderStyle: "solid",
    borderColor: state.isDisabled
      ? Styles.borderColorDisabled
      : state.isFocused
        ? Styles.borderColorFocus
        : Styles.borderColor,
    boxShadow: state.isFocused ? Styles.boxShadowFocus : "none",
    boxSizing: "border-box",
    cursor: "default",
    display: "flex",
    flexWrap: "wrap",
    fontSize: Styles.fontSize,
    justifyContent: "space-between",
    minHeight: Styles.minHeight,
    outline: "0 !important",
    position: "relative",
    "&:hover": {
      borderColor: state.isFocused
        ? Styles.borderColorFocus
        : Styles.borderColor,
      outline: "0",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    paddingTop: Styles.controlPaddingY,
    paddingBottom: Styles.controlPaddingY,
  }),
  groupHeading: () => ({
    background: "blue",
    display: "none",
  }),
  group: () => ({
    padding: "0",
    borderBottomWidth: Styles.groupBorderWidth,
    borderBottomColor: Styles.groupBorderColor,
    borderBottomStyle: "solid",
    ":last-child": {
      borderBottom: "none",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? Styles.optionBackgroundSelected
      : state.isFocused
        ? Styles.optionBackgroundFocused
        : "transparent",
    color: state.isDisabled
      ? Styles.optionTextColorDisabled
      : state.isSelected
        ? Styles.optionTextColorSelected
        : "inherit",
    // provide some affordance on touch devices
    ":active": {
      backgroundColor:
        !state.isDisabled &&
        (state.isSelected
          ? Styles.optionBackgroundSelected
          : Styles.optionBackgroundActive),
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? Styles.textColorDisabled : Styles.textColor,
  }),
};

CountryPicker.displayName = "CountryPicker";

CountryPicker.propTypes = {
  /**
   * Disables the select component and clears the value
   */
  disabled: PropTypes.bool,
  /**
   * Array of country codes to be displayed at the top of the list in a separated group
   * Chosen countries will also be removed from the full country list below
   */
  featured: PropTypes.arrayOf(PropTypes.oneOf(countryKeys)),
  /**
   * Unique indentifier to locate the element in the DOM
   */
  id: PropTypes.string,
  /**
   * Enable text filtering on the list
   */
  isSearchable: PropTypes.bool,
  /**
   * Constrain the maximum width of the cell. Pass a positive integer to set a maximum width in pixels.
   * Pass a string (e.g. maxWidth="10%") to use other units.
   */
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Set a minimum width for the cell. Pass a positive integer to set a minimum width in pixels.
   * Pass a string (e.g. minWidth="2rem") to use other units.
   */
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Callback on blur
   */
  onBlur: PropTypes.func,
  /**
   * Callback when changed
   */
  onChange: PropTypes.func,
  /**
   * Callback when focussed
   */
  onFocus: PropTypes.func,
  /**
   * Adds placeholder text to the select until a country has been chosen
   */
  placeholder: PropTypes.string,
  /**
   * Uses emoji characters to display flags in the option label
   */
  showFlags: PropTypes.bool,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
  /**
   * Set value programatically
   */
  value: PropTypes.oneOf(countryKeys),
};

CountryPicker.defaultProps = {
  disabled: false,
  featured: undefined,
  id: undefined,
  isSearchable: true,
  maxWidth: undefined,
  minWidth: undefined,
  onBlur: () => { },
  onChange: () => { },
  onFocus: () => { },
  placeholder: undefined,
  showFlags: true,
  tackons: undefined,
  value: undefined,
};

export default CountryPicker;
