// -----------------------------------------------------------------------------
// Breadcrumbs
// -----------------------------------------------------------------------------

//React
import React from "react";
import PropTypes from "prop-types";

//Utilities
import { ClassNameMaker, Tackons } from "utils";

//Styles
import Styles from "./breadcrumbs.module.scss";

/**
 * Breadcrumbs provide a visual indication of where the current page sits in the content hierarchy.
 */
function Breadcrumbs(props) {
  //Variables
  const { crumbs, id, tackons } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([Styles.container]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  const crumbElement = (crumb) => {
    if (crumb.onClick) {
      return (
        <button
          className={Styles.button}
          onClick={(e) => {
            e.preventDefault();
            crumb.onClick(e);
          }}
        >
          {crumb.label}
        </button>
      );
    }

    if (crumb.href) {
      return (
        <a className={Styles.link} href={crumb.href}>
          {crumb.label}
        </a>
      );
    }
    return <span className={Styles.leaf}>{crumb.label}</span>;
  };

  //Output
  return (
    <nav className={className} id={id} style={style} aria-label="Breadcrumb">
      <ol className={Styles.list}>
        {crumbs &&
          crumbs.map((c, i) => {
            let lastItem = i === crumbs.length - 1;
            return (
              <li
                className={Styles.crumb}
                key={i}
                aria-current={lastItem ? "page" : null}
              >
                {crumbElement(c)}
              </li>
            );
          })}
      </ol>
    </nav>
  );
}

Breadcrumbs.displayName = "Breadcrumbs";
Breadcrumbs.propTypes = {
  /**
   * Unique label to identify this element in the dom
   */
  id: PropTypes.string,
  /**
   * Array of options to be rendered
   */
  crumbs: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ).isRequired,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  id: undefined,
  crumbs: undefined,
  tackons: undefined,
};

export default Breadcrumbs;
