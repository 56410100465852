import React from "react";

import Popover from "components/popover";
import Avatar from "components/avatar";

import { MemberTypes } from "enums";

import Styles from "./userMenu.module.scss";

interface IUserMenuProps {
  /**
   * Avatars are colour schemed around user type
   */
  appearance?: MemberTypes;
  /**
   * Screen label for the user menu trigger
   */
  ariaLabel?: string;
  /**
   * Content to be displayed inside the Popover
   */
  children: React.ReactElement;
  /**
   * Unique name to identify this element in the DOM. This field is required for accessibility attributes
   */
  id: string;
  /**
   * Member name to be used for the avatar initials
   */
  name: string;
  /**
   * Prop set by the parent TopBar component to position the popover underneath the container
   * */
  offset: [number, number];
}

function UserMenu(props: IUserMenuProps) {
  const { ariaLabel, appearance, id, children, name, offset } = props;

  const TriggerMarkup = name ? (
    <button aria-label={ariaLabel} className={Styles.button}>
      <Avatar name={name} appearance={appearance} />
    </button>
  ) : null;

  return (
    <Popover
      id={id}
      trigger={TriggerMarkup}
      placement="bottom-end"
      offset={offset}
    >
      {children}
    </Popover>
  );
}

UserMenu.defaultProps = {
  appearance: "patient",
  ariaLabel: undefined,
  children: undefined,
  id: undefined,
  name: undefined,
};

export default UserMenu;
