import React from "react";
import PropTypes from "prop-types";

//Utilities
import { ClassNameMaker, Tackons } from "utils";

//Styles
import Styles from "./textContainer.module.scss";

function TextContainer(props) {
  const { align, children, id, tackons } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    align && Styles[`align-${align}`],
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  //Output

  return (
    <div id={id} className={className} style={style}>
      {children}
    </div>
  );
}

TextContainer.displayName = "TextContainer";

TextContainer.propTypes = {
  /**
   * Text alignment for all content
   */
  align: PropTypes.oneOf(["left", "center", "right"]),
  /**
   * Content to display inside the TextContainer
   */
  children: PropTypes.node,
  /**
   * Unique identifier
   */
  id: PropTypes.string,
  /**
   * Control the spacing between the html elements
   */
  spacing: PropTypes.oneOf(["tight", "default", "loose", "none"]),
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
};

TextContainer.defaultProps = {
  align: "left",
  children: undefined,
  id: undefined,
  spacing: "default",
  tackons: undefined,
};

export default TextContainer;
