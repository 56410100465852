import React from "react";
import PropTypes from "prop-types";

import Styles from "./headerRow.module.scss";

// Utilities
import { ClassNameMaker, Tackons } from "utils";

export function HeaderRow(props) {
  const { children, density, tackons } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    Styles[`density-${density}`]
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return (
    <div className={className} style={style} role="row">
      {children}
    </div>
  );
}

HeaderRow.displayName = "HeaderRow";

HeaderRow.propTypes = {
  /**
   * Content to display inside the row
   */
  children: PropTypes.node,
  /**
   * How much padding inside the row
   */
  density: PropTypes.oneOf(["compact", "default", "comfortable"]),
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string
};

HeaderRow.defaultProps = {
  children: undefined,
  density: "default",
  tackons: undefined
};

export default HeaderRow;
