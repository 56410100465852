// -----------------------------------------------------------------------------
// Choice List
// -----------------------------------------------------------------------------

//React
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import Checkbox from "components/checkbox";
import Radio from "components/radio";
import Icon from "components/icon";

//Utilities
import { ClassNameMaker, Tackons } from "utils";

// Styles

import Styles from "./choiceList.module.scss";

import { hatefulWayToProgrammaticallyTriggerOnChangeEvent } from "../../hacks";

const booleanDefaults = {
  enabled: false,
  true: "Yes",
  false: "No",
};

const booleanChoicesDefaults = {
  true: { label: booleanDefaults.true, value: booleanDefaults.true },
  false: { label: booleanDefaults.false, value: booleanDefaults.false },
};

function ChoiceList(props) {
  const {
    disabled = false,
    onChange,
    hint,
    id,
    name,
    invalid,
    label,
    labelHidden,
    orientation,
    selected,
    tackons,
    boolean,
  } = props;

  const isBoolean = typeof boolean === "boolean" ? boolean : boolean?.enabled;

  const selectMultiple = isBoolean ? false : props.selectMultiple;

  let choices = isBoolean ? normalizeBooleanChoices(props) : props.choices;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.group,
    labelHidden && Styles["labelHidden"],
    orientation && Styles[orientation],
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  const [isSelected, setSelected] = useState(selected || []);

  const hiddenSide = useRef(null);

  const handleChange = (e) => {
    const on = e.target.checked;
    const val = e.target.value;
    let newSelection;
    if (selectMultiple) {
      if (!on) {
        newSelection = (isSelected || []).filter((s) => s !== val);
      } else if (!(isSelected || []).includes(val)) {
        newSelection = [...isSelected, val];
      }
    } else if (on) {
      newSelection = [val];
    }

    setSelected(newSelection);

    //
    hatefulWayToProgrammaticallyTriggerOnChangeEvent(hiddenSide, newSelection);
  };

  useEffect(() => {
    setSelected(selected || []);
  }, [selected]);

  //Output

  return (
    <fieldset className={className} style={style} id={id} role="group">
      {label && (
        <legend className={Styles.label}>
          <span className={Styles.text}>{label}</span>
        </legend>
      )}
      {hint && <div className={Styles.hint}>{hint}</div>}
      <div className={Styles.options}>
        {Object.keys(choices).map((cname, i) => {
          const c = choices[cname];
          if (selectMultiple) {
            return (
              <Checkbox
                label={c.label}
                id={`${id}-option-${c.value}`}
                disabled={disabled || c.disabled}
                invalid={typeof invalid === "string" ? true : invalid}
                checked={isSelected && isSelected.includes(c.value)}
                hint={c.hint}
                key={c.value}
                value={c.value}
                onChange={(e) => handleChange(e)}
              />
            );
          }
          return (
            <Radio
              label={c.label}
              id={`${id}-option-${c.value}`}
              name={id}
              value={c.value}
              disabled={disabled || c.disabled}
              invalid={typeof invalid === "string" ? true : invalid}
              checked={isSelected && isSelected.includes(c.value)}
              hint={c.hint}
              key={c.value}
              onChange={(e) => handleChange(e)}
            />
          );
        })}
      </div>
      {invalid && typeof invalid === "string" && (
        <div className={Styles.invalidText} aria-live="polite">
          <Icon name="exclamation-circle" width="16" height="16" decorative />
          {invalid}
        </div>
      )}
      <input
        id={id}
        type="text"
        name={name}
        style={{ display: "none" }}
        value={selected}
        ref={hiddenSide}
        onChange={onChange}
      />
    </fieldset>
  );
}

ChoiceList.displayName = "ChoiceList";

ChoiceList.propTypes = {
  /**
   * Array of choices to be rendered
   */
  choices: PropTypes.arrayOf(PropTypes.object),
  /**
   * Disable all choices within the fieldset
   */
  disabled: PropTypes.bool,
  /**
   * Callback when individual choices trigger onChange
   */
  onChange: PropTypes.func,
  /**
   * Additional text to support the label
   */
  hint: PropTypes.string,
  /**
   * Unique identifier - used to generate id and name for choices.
   */
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  /**
   * May be added as a prop to toggle or the invalid state, or given a string to show an error message.
   */
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Rendered as an html <legend> to the fieldset container
   */
  label: PropTypes.string,
  /**
   * Visually hide the label (still visible to screenreaders)
   */
  labelHidden: PropTypes.bool,
  /**
   * Arrange the choices horizontally or vertically
   */
  orientation: PropTypes.oneOf(["horizontal", "vertical"]),
  /**
   * Array of values from the choices to indicate which options have been selected.
   * Note: if selectMultiple is false array length must be one.
   */
  selected(props, propName) {
    let error = null;
    if (props.selected && !Array.isArray(props.selected)) {
      error = new Error(`${propName} needs to be an array`);
    }
    if (!props.selectMultiple && props.selected && props.selected.length > 1) {
      error = new Error(
        `${propName} should be an array of length 1 if selectMultiple is false`
      );
    }
    return error;
  },
  /**
   * Render choices as either radio buttons or as checkboxes
   */
  selectMultiple: PropTypes.bool,
  /**
   * Render choices as radio buttons, it doesn't support props.selectMultiple
   */
  boolean: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      enabled: PropTypes.bool,
      /**
       * Label for Yes value
       */
      true: PropTypes.string,
      /**
       * Label for No value
       */
      false: PropTypes.string,
    }),
  ]),
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
};

ChoiceList.defaultProps = {
  choices: [],
  name: undefined,
  disabled: false,
  onChange: () => { },
  hint: undefined,
  invalid: undefined,
  label: undefined,
  labelHidden: undefined,
  orientation: undefined,
  selected: undefined,
  selectMultiple: false,
  boolean: booleanDefaults,
  tackons: undefined,
};

export default ChoiceList;

function normalizeBooleanChoices({ choices = [], boolean }) {
  let [trueChoice, falseChoice] = choices.length
    ? choices
    : Object.values(booleanChoicesDefaults);

  if (typeof boolean === "boolean" && choices?.length) {
    trueChoice.value = booleanDefaults.true;
    falseChoice.false = booleanDefaults.false;
  } else if (typeof boolean !== "boolean") {
    trueChoice = {
      ...trueChoice,
      value: booleanChoicesDefaults.true.value,
      label: boolean.true || booleanChoicesDefaults.true.label,
    };
    falseChoice = {
      ...falseChoice,
      value: booleanChoicesDefaults.false.value,
      label: boolean.false || booleanChoicesDefaults.false.label,
    };
  }

  return [trueChoice, falseChoice];
}
