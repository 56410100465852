export const breakpointKeys = {
  XSMALL: "xs", // mobile
  SMALL: "sm", // "phablet"
  MEDIUM: "md", // "tablet"
  LARGE: "lg", // "desktop"
  XLARGE: "xl", // "xl-desktop"
};

export const breakpointDevices = {
  xs: "mobile",
  sm: "phablet",
  md: "tablet",
  lg: "desktop",
  xl: "xl-desktop",
};

export type MemberTypes = "patient" | "therapist";

export type OnlineStatuses = "hidden" | "online" | "offline";

export type SpacingSizes = "none" | "xs" | "sm" | "md" | "lg" | "xl";
