// -----------------------------------------------------------------------------
// Avatar
// -----------------------------------------------------------------------------

/**
 * @info: General component description in JSDoc format. Markdown is *supported*.
 */

import React from "react";
import PropTypes from "prop-types";

import { MemberTypes, OnlineStatuses } from "enums";

// Styles
import Styles from "./avatar.module.scss";

// Utilities
import { ClassNameMaker, Tackons } from "utils";
import { getInitials } from "./initials";
//import utilityStyles from "utils";

interface AvatarProps {
  /**
   * Avatars are colour schemed around user type
   */
  appearance: MemberTypes;
  /**
   * Visually hidden button label for screen-readers
   */
  ariaLabel?: string;
  /**
   * Used as the default for the Aria label unless overridden
   */
  name: string | null | undefined;
  /**
   * Shown an online / offline indicator
   */
  onlineStatus: OnlineStatuses;
  /**
   * Change the size of the avatar
   */
  size: "small" | "medium" | "large";
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: string;
}

function Avatar(props: AvatarProps) {
  const { appearance, ariaLabel, name, onlineStatus, size, tackons } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    appearance && Styles[appearance],
    size && Styles[size],
  ]);

  const initials = getInitials(name);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return (
    <div
      className={className}
      aria-label={ariaLabel ? ariaLabel : name}
      style={style}
      role="img"
    >
      {initials}
      {onlineStatus !== "hidden" && <span className={Styles[onlineStatus]} />}
    </div>
  );
}

Avatar.displayName = "Avatar";

Avatar.propTypes = {
  /**
   * Avatars are colour schemed around user type
   */
  appearance: PropTypes.oneOf(["patient", "therapist"]),
  /**
   * Visually hidden button label for screen-readers
   */
  ariaLabel: PropTypes.string,
  /**
   * Used as the default for the Aria label unless overridden
   */
  name: PropTypes.string.isRequired,
  /**
   * Shown an online / offline indicator
   */
  onlineStatus: PropTypes.oneOf(["hidden", "online", "offline"]),
  /**
   * Change the size of the avatar
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
};

Avatar.defaultProps = {
  appearance: "patient",
  ariaLabel: undefined,
  name: undefined,
  onlineStatus: "hidden",
  size: "medium",
  tackons: undefined,
};

export default Avatar;
