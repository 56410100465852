import React from "react";
import Icon from "components/icon";

import Styles from "./action.module.scss";

import { ClassNameMaker, Tackons } from "utils";

import {
  BaseAction,
  LinkAction,
  DestructiveAction,
  IconableAction,
  DisableableAction,
} from "types";

export interface IActionListActionProps
  extends BaseAction,
    LinkAction,
    DestructiveAction,
    DisableableAction,
    IconableAction {
  /** Set hint text for the action  */
  hint?: string;
}

function Action(props: IActionListActionProps) {
  const {
    ariaLabel,
    destructive,
    disabled,
    external,
    id,
    label,
    hint,
    icon,
    onAction,
    url,
    tackons,
  } = props;

  const ClassName = ClassNameMaker([
    Styles.container,
    destructive && Styles.destructive,
    disabled && Styles.disabled,
    hint && Styles.twoLine,
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  const textContent = (
    <span className={Styles.content}>
      <span className={Styles.label}>{label}</span>
      {hint && <span className={Styles.hint}>{hint}</span>}
    </span>
  );

  const iconContent = icon && (
    <span className={Styles.icon}>
      <Icon name={icon} decorative width={20} height={20} />
    </span>
  );

  return url ? (
    <a
      id={id}
      className={ClassName}
      href={url}
      target={external ? "_blank" : null}
      rel={external ? "noopener noreferrer" : null}
      aria-label={ariaLabel}
      style={style}
    >
      {iconContent}
      {textContent}
    </a>
  ) : (
    <button
      id={id}
      className={ClassName}
      onClick={onAction}
      disabled={disabled}
      aria-label={ariaLabel}
      style={style}
    >
      {iconContent}
      {textContent}
    </button>
  );
}

Action.defaultProps = {
  ariaLabel: undefined,
  destructive: false,
  disabled: false,
  external: false,
  label: undefined,
  hint: undefined,
  icon: undefined,
  onAction: () => {},
  url: undefined,
  tackons: undefined,
};
export default Action;
