import React from "react";

import { IActionListActionProps } from "./action";

// Styles
import Styles from "./section.module.scss";

//Utilities
import { ClassNameMaker, Tackons } from "utils";

export interface IActionListSectionProps {
  /**
   * Unique label to identify this element in the dom
   */
  id: string;
  /**
   * Use children to describe a custom arrangement of items in this section using `<ActionList.Action/>`as needed.
   */
  children:
    | React.ReactElement<IActionListActionProps>[]
    | React.ReactElement<IActionListActionProps>;
  /**
   * Functional CSS add-ons to override margin, position etc.
   */

  tackons: string;
}

function Section(props: IActionListSectionProps) {
  const { id, children, tackons } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([Styles.container]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return React.createElement(
    "section",
    {
      className: className,
      style: style,
      id: id,
    },
    children
  );
}

Section.defaultProps = {
  children: undefined,
  id: undefined,
  tackons: undefined,
};

export default Section;
