import * as React from "react";

import { breakpointKeys, SpacingSizes } from "enums";

import { ClassNameMaker, Tackons } from "utils";

import Styles from "./Layout.module.scss";

type Structure =
  | "two-thirds-start"
  | "two-thirds-end"
  | "two-even"
  | "three-even"
  | "four-even"
  | "single";

type RowColumnSpacing = {
  column: SpacingSizes;
  row: SpacingSizes;
};

type StructureWithBreakpoint = {
  breakpoint?: keyof typeof breakpointKeys;
  structure: Structure;
  gap?: boolean | SpacingSizes | RowColumnSpacing;
};

export interface OwnProps {
  children: React.ReactNode;
  gap?: boolean | SpacingSizes | RowColumnSpacing;
  structure?: Structure | StructureWithBreakpoint[];
  tackons?: string;
}

export type ILayoutProps = OwnProps & React.HTMLProps<HTMLDivElement>;

/**
 * Layout is used to create common layout patterns within a page using the power of CSS grid.
 */
const Layout: React.FC<ILayoutProps> = ({
  children,
  gap,
  structure,
  tackons,
  ...props
}) => {
  const gapMaker = (
    gap: boolean | SpacingSizes | RowColumnSpacing,
    breakpoint?: keyof typeof breakpointKeys
  ) => {
    let gapClasses: string[] = [];
    if (gap && typeof gap === "boolean")
      gapClasses.push(Styles[`gap-md${breakpoint ? "--" + breakpoint : ""}`]);
    if (gap && typeof gap === "string")
      gapClasses.push(
        Styles[`gap-${gap}${breakpoint ? "--" + breakpoint : ""}`]
      );
    if (gap && typeof gap === "object") {
      gap.row &&
        gapClasses.push(
          Styles[`gap-row-${gap.row}${breakpoint ? "--" + breakpoint : ""}`]
        );
      gap.column &&
        gapClasses.push(
          Styles[
            `gap-column-${gap.column}${breakpoint ? "--" + breakpoint : ""}`
          ]
        );
    }
    return gapClasses;
  };

  const structureMaker = (
    structure: Structure,
    breakpoint?: keyof typeof breakpointKeys
  ) => {
    return Styles[`${structure}${breakpoint ? "--" + breakpoint : ""}`];
  };

  const responsiveMaker = (structure: StructureWithBreakpoint[]) => {
    let responsiveClasses: string[] = [];
    if (structure && Array.isArray(structure))
      structure.forEach((s) => {
        const { structure, gap, breakpoint } = s;
        responsiveClasses.push(structureMaker(structure, breakpoint));
        gap && responsiveClasses.push(...gapMaker(gap, breakpoint));
      });
    return responsiveClasses;
  };

  const className = ClassNameMaker([
    Styles.container,
    ...gapMaker(gap),
    structureMaker(structure as Structure),
    ...responsiveMaker(structure as StructureWithBreakpoint[]),
  ]);

  const style = tackons && Tackons(tackons);

  return (
    <div className={className} {...props} style={style}>
      {children}
    </div>
  );
};

Layout.displayName = "Layout";
Layout.defaultProps = {};

export default Layout;
