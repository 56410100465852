// -----------------------------------------------------------------------------
// Tooltip
// -----------------------------------------------------------------------------

// React
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import tokens from "styles/tokens/tokens.ts.scss";

// Utilities
import { StyleMaker } from "utils";

// Styles
import Styles from "./tooltip.module.scss";

function Tooltip(props) {
  const {
    children,
    forRef,
    maxWidth,
    minWidth,
    onShow,
    onHide,
    position,
    tackons,
    triangle,
    background,
  } = props;

  const style = StyleMaker(tackons, [
    { minWidth: minWidth },
    { maxWidth: maxWidth },
  ]);

  const tooltipRef = useRef(null);

  type TTooltipStyle = {
    top?: any;
    bottom?: any;
    left?: any;
    right?: any;
    opacity: number;
  };

  const [show, setShow] = useState(false);
  const [tooltipStyle, setTooltipStyle] = useState<TTooltipStyle>({
    top: 0,
    bottom: undefined,
    left: 0,
    right: undefined,
    opacity: 0,
  });

  const handleMouseOver = () => {
    setShow(true);
    onShow();
  };
  const handleMouseOut = () => {
    setShow(false);
    onHide();
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const node = forRef && forRef.current;
    if (node) {
      node.addEventListener("mouseover", handleMouseOver);
      node.addEventListener("mouseout", handleMouseOut);

      return () => {
        node.removeEventListener("mouseenter", handleMouseOver);
        node.removeEventListener("mouseleave", handleMouseOut);
      };
    }
  }, [forRef]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const tooltip = tooltipRef && tooltipRef.current;
    if (tooltip) {
      setTooltipStyle(
        getTooltipPosition(
          position,
          forRef.current.getBoundingClientRect(),
          tooltip.getBoundingClientRect()
        )
      );
    }
  }, [tooltipRef.current, position, forRef]);

  const styles = { ...style, ...tooltipStyle, ...getTooltipStyle(background) };

  // Output

  return show ? (
    <div ref={tooltipRef} style={styles} className={Styles.tooltipWrapper}>
      <div
        className={`${Styles.tooltip} ${triangle ? selectClass(position) : null
          }`}
      >
        {children}
      </div>
      {triangle ? (
        <div
          className={`${Styles.triangle} ${triangle ? selectTriangleClass(position) : null
            }`}
        ></div>
      ) : null}
    </div>
  ) : null;
}

Tooltip.displayName = "Tooltip";

function getTooltipPosition(position, nodePosition, tooltipPosition) {
  const { top, left, bottom, right, height, width } = nodePosition;

  switch (position) {
    case "top":
      return {
        opacity: 1,
        top: top - tooltipPosition.height,
        left: left + (width - tooltipPosition.width) / 2,
      };
    case "bottom":
      return {
        opacity: 1,
        top: bottom,
        left: left + (width - tooltipPosition.width) / 2,
      };
    case "left":
      return {
        opacity: 1,
        top: top + (height - tooltipPosition.height) / 2,
        right: window.innerWidth - left,
      };
    case "right":
      return {
        opacity: 1,
        top: top + (height - tooltipPosition.height) / 2,
        left: right,
      };
    default:
      break;
  }
}

function selectClass(position) {
  switch (position) {
    case "top":
      return Styles.tooltipTop;
    case "bottom":
      return Styles.tooltipBottom;
    case "left":
      return Styles.tooltipLeft;
    case "right":
      return Styles.tooltipRight;
    default:
      break;
  }
}

function selectTriangleClass(position) {
  switch (position) {
    case "top":
      return Styles.triangleTop;
    case "bottom":
      return Styles.triangleBottom;
    case "left":
      return Styles.triangleLeft;
    case "right":
      return Styles.triangleRight;
    default:
      break;
  }
}

function getTooltipStyle(background) {
  if (background === "dark") {
    return {
      "--color": tokens["neutral-white"],
      "--bgColor": tokens["shade-base"],
    };
  }

  return {
    "--color": tokens["shade-base"],
    "--bgColor": tokens["neutral-white"],
  };
}

Tooltip.propTypes = {
  /**
   * Tooltip background theme
   */
  background: PropTypes.oneOf(["light", "dark"]),
  /**
   * Content to display inside the stack tooltip
   */
  children: PropTypes.node,
  /**
   * React reference to element on which tooltip will be shown
   */
  forRef: PropTypes.object,
  /**
   * Constrain the maximum width of the tooltip. Pass a positive integer to set a maximum width in pixels.
   * Pass a string (e.g. maxWidth="10%") to use other units.
   */
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Set a minimum width for the tooltip. Pass a positive integer to set a minimum width in pixels.
   * Pass a string (e.g. minWidth="2rem") to use other units.
   */
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Callback on show tooltip
   */
  onShow: PropTypes.func,
  /**
   * Callback on hide tooltip
   */
  onHide: PropTypes.func,
  /**
   * Tooltip position
   */
  position: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
  /**
   * Add triangle to tooltip
   */
  triangle: PropTypes.bool,
};

Tooltip.defaultProps = {
  background: "dark",
  children: undefined,
  forRef: undefined,
  maxWidth: undefined,
  minWidth: undefined,
  onShow: () => { },
  onHide: () => { },
  position: "top",
  triangle: true,
  tackons: undefined,
};

export default Tooltip;
