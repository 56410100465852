import React from "react";
import { ClassNameMaker } from "utils";
import Styles from "./stackPanel.module.scss";

type Orientation = "horizontal" | "vertical";

type MediaQueryBasedOrientation = {
    readonly medium?: Orientation;
    readonly large?: Orientation;
}

type StackPanelProps = {
    readonly className?: string;
    readonly layout: Orientation | MediaQueryBasedOrientation;
    readonly gutter?: boolean;
}

const StackPanel: React.FC<StackPanelProps> = props => {
    const { className, layout } = props;
    const classNames = [className, Styles.container, props.gutter ? "gutter" : ""];

    if (typeof layout === "string") {
        classNames.push(Styles[layout]);
    } else {
        if (layout.medium) {
            classNames.push(Styles[`medium-${layout.medium}`]);
        }

        if (layout.large) {
            classNames.push(Styles[`large-${layout.large}`]);
        }
    }

    return <div className={ClassNameMaker(classNames)}>{props.children}</div>;
}

StackPanel.displayName = "StackPanel";
StackPanel.defaultProps = {
    layout: "vertical",
};

export default StackPanel;
