import React from "react";

import Styles from "./menuItem.module.scss";

import { ClassNameMaker, Tackons } from "utils";

import type { BaseAction, LinkAction } from "types";

export interface IMenuItemProps extends BaseAction, LinkAction {
  /*** Display active styling */
  active?: boolean;
}

function MenuItem(props: IMenuItemProps) {
  const {
    ariaLabel,
    id,
    active,
    external,
    label,
    onAction,
    tackons,
    url,
  } = props;

  const className = ClassNameMaker([
    Styles.container,
    active && Styles["active"],
  ]);

  const style = tackons && Tackons(tackons);
  return (
    <li className={className} style={style}>
      <a
        onClick={onAction}
        className={Styles.link}
        href={url}
        id={id}
        rel={external ? "noopener noreferrer" : null}
        target={external ? "_blank" : null}
        aria-label={ariaLabel}
      >
        {label}
      </a>
    </li>
  );
}

export default MenuItem;
