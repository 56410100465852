import React from "react";
import PropTypes from "prop-types";

import Icon from "components/icon";

// Styles
import Styles from "../collapsible.module.scss";

// Utilities
import { ClassNameMaker, Tackons } from "utils";

function Trigger(props) {
  const { ariaControls, id, isOpen, label, onToggle, tackons } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.trigger,
    isOpen && Styles["triggerActive"]
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);
  return (
    <>
      {label && (
        <button
          aria-expanded={isOpen}
          className={className}
          style={style}
          onClick={() => onToggle()}
          type="button"
          id={id}
          aria-controls={ariaControls}
        >
          <span className={Styles.triggerLabel}>{label}</span>
          <span className={Styles.triggerIcon}>
            <Icon name="add" decorative />
          </span>
        </button>
      )}
    </>
  );
}

Trigger.displayName = "Trigger";

Trigger.propTypes = {
  /**
   * id of the Collapsible panel for accessibility
   */
  ariaControls: PropTypes.string,
  /**
   * Unique identifier for element in DOM
   */
  id: PropTypes.string,
  /**
   * State of the collapsible - used to trigger the active styling
   */
  isOpen: PropTypes.bool,
  /**
   * Label for the trigger
   */
  label: PropTypes.string,
  /**
   * Function to execute when the trigger is clickec
   */
  onToggle: PropTypes.func,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string
};

Trigger.defaultProps = {
  tackons: undefined
};

export default Trigger;
