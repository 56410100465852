import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

// Dependencies
import Trigger from "./components/trigger";
import Panel from "./components/panel";

// Styles
import Styles from "./collapsible.module.scss";

// Utilities
import { ClassNameMaker, Tackons } from "utils";

/**
 * Collapsible is used to put long sections of information inside sections that can be hidden and shown by the user.
 */
function Collapsible(props, ref) {
  const { children, id, isOpen, label, separators, tackons } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    separators && Styles["separators"],
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  const content = useRef(null);

  const [open, setOpen] = useState(isOpen ? true : false);
  const [panelHeight, setPanelHeight] = useState(
    isOpen ? `${content.current.scrollHeight}px` : "0px"
  );

  const toggle = () => {
    setOpen(!open);
    setPanelHeight(open ? "0px" : `${content.current.scrollHeight}px`);
  };

  return (
    <div id={id} className={className} style={style}>
      {label ? (
        <>
          <Collapsible.Trigger
            label={label}
            ariaControls={`${id}-panel`}
            onToggle={toggle}
            isOpen={open}
            id={`${id}-trigger`}
          />
          <Collapsible.Panel
            id={`${id}-panel`}
            isOpen={open}
            height={panelHeight}
            forwardedRef={content}
          >
            {children}
          </Collapsible.Panel>
        </>
      ) : (
        children
      )}
    </div>
  );
}

Collapsible.displayName = "Collapsible";
Collapsible.Trigger = Trigger;
Collapsible.Panel = Panel;

Collapsible.propTypes = {
  /**
   * If a label is supplied standard collapsible presentation will be used,
   * children will be rendered inside the collapsed panel. If no label is supplied
   * custom collapsible presentation will be used and children will be rendered.
   */
  children: PropTypes.node,
  /**
   * Unique identifier for element in DOM
   */
  id: PropTypes.string.isRequired,
  /**
   * Should the collapsible be open by default?
   */
  isOpen: PropTypes.bool,
  /**
   * Label for the trigger
   */
  label: PropTypes.string,
  /**
   * Show separator lines between multiple collapsibles
   * Useful for FAQ type presentations
   */
  separators: PropTypes.bool,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
};

Collapsible.defaultProps = {
  children: undefined,
  id: undefined,
  isOpen: false,
  label: undefined,
  separators: false,
  tackons: undefined,
};

export default Collapsible;
