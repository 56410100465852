import React from "react";

import Popover from "components/popover";
import Icon from "components/icon";

import IconPaths from "components/icon/iconPaths.generated";

import Styles from "./iconMenu.module.scss";
import { ClassNameMaker } from "utils";

export interface IIconMenuProps {
  /**
   * Accessibility label to be applied to the trigger button
   */
  ariaLabel?: string;
  /**
   * Adds a blue dot to indicate that new items are available to view
   */
  attention?: boolean;
  /**
   * Content to be displayed inside the popover
   */
  children?: React.ReactElement;
  /**
   * Icon to be used as the trigger
   */
  icon: keyof typeof IconPaths;
  /**
   * Prop set by the parent TopBar component to position the popover underneath the container
   * */
  offset: [number, number];
  /**
   * Unique name to identify the element in the DOM (required for accessibility labels)
   */
  id: string;
}

function IconMenu(props: IIconMenuProps) {
  const { ariaLabel, id, icon, children, offset, attention } = props;

  const className = ClassNameMaker([
    Styles.button,
    attention && Styles["attention"],
  ]);

  const TriggerMarkup = icon ? (
    <button aria-label={ariaLabel} className={className}>
      <Icon name={icon} width={20} height={20} decorative />
    </button>
  ) : null;
  return (
    <Popover
      id={id}
      trigger={TriggerMarkup}
      placement="bottom-end"
      offset={offset}
    >
      {children}
    </Popover>
  );
}

IconMenu.defaultProps = {
  ariaLabel: undefined,
  attention: false,
  children: undefined,
  icon: undefined,
  id: undefined,
};

export default IconMenu;
