// -----------------------------------------------------------------------------
// Tab
// -----------------------------------------------------------------------------

//React
import React from "react";
import PropTypes from "prop-types";

//Utilities
import { ClassNameMaker, Tackons } from "utils";

//Styles
import Styles from "./tabs.module.scss";

function Tabs(props) {
  // props
  const { activeTab, fullWidth, id, tabs, tackons } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    fullWidth && Styles.fullWidth
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  //Output

  return (
    <div className={className} style={style} id={id} role="tablist">
      {tabs.map((t, i) => {
        const active = activeTab === t.id;
        const tabClass = ClassNameMaker([Styles.tab, active && Styles.active]);
        return (
          <button
            className={tabClass}
            key={i}
            id={t.id}
            onClick={t.onSelect}
            role="tab"
            aria-selected={active}
            aria-controls={t.ariaControls}
            type="button"
          >
            <span className={Styles.label}>{t.label}</span>
          </button>
        );
      })}
    </div>
  );
}

Tabs.displayName = "Tabs";

Tabs.propTypes = {
  /**
   * Id of the active tab
   */
  activeTab: PropTypes.string,
  /**
   * Accessibility label for the control
   */
  ariaLabel: PropTypes.string,
  /**
   * Expand the tab width to fill the container width
   */
  fullWidth: PropTypes.bool,
  /**
   * Unique indentifier for the element
   */
  id: PropTypes.string,
  /**
   * Array of tab information to be rendered
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      onSelect: PropTypes.func.isRequired,
      ariaControls: PropTypes.string
    })
  ).isRequired,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string
};

Tabs.defaultProps = {
  activeTab: undefined,
  ariaLabel: undefined,
  fullWidth: false,
  id: undefined,
  tabs: undefined,
  tackons: undefined
};

export default Tabs;
