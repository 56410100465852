import React from "react";
import PropTypes from "prop-types";

import Col from "./components/col";
import Row from "./components/row";

// Styles
import Styles from "./grid.module.scss";

//Utilities
import { ClassNameMaker, Tackons } from "utils";

/**
 * Grid is a utility layout component to lay content out in series of rows and columns using flexbox.
 * Columns can be given different widths and offsets at multiple breakpoints. Inspired by https://evgenyrodionov.github.io/flexboxgrid2/
 */

// type Grid = React.FC & { Col: Col };

function Grid(props) {
  const { id, children, fullWidth, tagName, tackons } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    fullWidth && Styles["container-fill"],
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return React.createElement(
    tagName || "div",
    {
      className: className,
      style: style,
      id: id,
    },
    children
  );
}

Grid.Row = Row;
Grid.Col = Col;

Grid.propTypes = {
  /**
   * Unique indentifier in the DOM
   */
  id: PropTypes.string,
  /**
   * Content to display - this should usually be <Grid.Row/> child components.
   */
  children: PropTypes.node,
  /**
   * Should the grid container be constrained to the dimensions of the largest breakpoint or fill the entire width of the parent?
   */
  fullWidth: PropTypes.bool,
  /**
   * Which element should be used to render the row
   */
  tagName: PropTypes.string,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
};

Grid.defaultProps = {
  id: undefined,
  children: undefined,
  fullWidth: false,
  tagName: "div",
  tackons: undefined,
};

export default Grid;
