// -----------------------------------------------------------------------------
// Select
// -----------------------------------------------------------------------------

//React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Icon from "components/icon";

// Utilities
import { ClassNameMaker, StyleMaker } from "utils";

//Styles
import Styles from "./select.module.scss";

function Select(props) {
  const {
    disabled, //
    hint,
    id,
    invalid,
    label,
    labelHidden,
    maxWidth,
    minWidth,
    name,
    onBlur,
    onChange,
    onFocus,
    options,
    placeholder,
    tackons,
    value,
  } = props;
  //Variables

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    disabled && Styles["disabled"],
    invalid && Styles["invalid"],
    labelHidden && Styles["labelHidden"],
  ]);

  let style = StyleMaker(tackons, [
    { minWidth: minWidth },
    { maxWidth: maxWidth },
  ]);

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = (e) => {
    setCurrentValue(e.target.value);
    onChange(e);
  };

  //Output

  return (
    <div className={className} style={style}>
      <label htmlFor={id} className={Styles.label}>
        <span className={Styles.text}>{label}</span>
      </label>
      {hint && <div className={Styles.hint}>{hint}</div>}
      <select
        className={Styles.input}
        id={id}
        name={name}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={handleChange}
        value={currentValue}
        aria-invalid={invalid && typeof invalid == "string" ? true : invalid}
      >
        {placeholder && <option disabled>{placeholder}</option>}
        {options.map((o, i) => {
          return (
            <option value={o.value} key={i}>
              {o.label}
            </option>
          );
        })}
      </select>
      {invalid && typeof invalid === "string" && (
        <div className={Styles.invalidText}>
          <Icon name="exclamation-circle" width="16" height="16" decorative />
          {invalid}
        </div>
      )}
    </div>
  );
}

Select.displayName = "Select";

Select.propTypes = {
  /**
   * Add disabled attribute to the input
   */
  disabled: PropTypes.bool,
  /**
   * Additional text to support the label
   */
  hint: PropTypes.string,
  /**
   * Unique indentifier that ties the label to the input
   */
  id: PropTypes.string.isRequired,
  /**
   * May be added as a prop to toggle or the invalid state, or given a string to show an error message.
   */
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Label for the checkbox
   */
  label: PropTypes.string.isRequired,
  /**
   * Visually hide the label (still visible to screenreaders)
   */
  labelHidden: PropTypes.bool,
  /**
   * Constrain the maximum width of the cell. Pass a positive integer to set a maximum width in pixels.
   * Pass a string (e.g. maxWidth="10%") to use other units.
   */
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Set a minimum width for the cell. Pass a positive integer to set a minimum width in pixels.
   * Pass a string (e.g. minWidth="2rem") to use other units.
   */
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Used to identify form data after it has been submitted to the server
   */
  name: PropTypes.string,
  /**
   * Callback on blur
   */
  onBlur: PropTypes.func,
  /**
   * Callback when changed
   */
  onChange: PropTypes.func,
  /**
   * Callback when focussed
   */
  onFocus: PropTypes.func,
  /**
   * Array of options to be rendered
   */
  options: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  /**
   * Adds a disabled option with no value as a placeholder text
   */
  placeholder: PropTypes.string,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
  /**
   * Set which option should be selected
   */
  value: PropTypes.string,
};

Select.defaultProps = {
  disabled: false,
  hint: undefined,
  id: undefined,
  invalid: undefined,
  label: undefined,
  labelHidden: false,
  maxWidth: undefined,
  minWidth: undefined,
  name: undefined,
  onBlur: () => { },
  onChange: () => { },
  onFocus: () => { },
  options: undefined,
  placeholder: undefined,
  tackons: undefined,
  value: undefined,
};

export default Select;
