import React from "react";
import PropTypes from "prop-types";
import Row from "./components/row";
import Cell from "./components/cell";
import HeaderRow from "./components/headerRow";
import HeaderCell from "./components/headerCell";

// Styles
import Styles from "./dataGrid.module.scss";

// Utilities
import { ClassNameMaker, Tackons } from "utils";

function DataGrid(props) {
  const { children, tackons } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([Styles.table]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return (
    <div className={className} style={style} role="grid">
      {children}
    </div>
  );
}

DataGrid.displayName = "DataGrid";

DataGrid.Row = Row;
DataGrid.Cell = Cell;
DataGrid.HeaderRow = HeaderRow;
DataGrid.HeaderCell = HeaderCell;

DataGrid.propTypes = {
  /**
   * Content to display inside the table
   */
  children: PropTypes.node,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string
};

DataGrid.defaultProps = {
  children: undefined,
  tackons: undefined
};

export default DataGrid;
