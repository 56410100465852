import React from "react";
import Button from "../button";
import Text from "../text";
import Styles from "./modal.module.scss";

interface IModalProps {
  /**
   * Unique modal identifier
   */
  id: string,
  /**
   * Header content
   */
  header?: JSX.Element | string,
  /**
   * Footer content
   */
  footer?: JSX.Element | string,
  /**
   * Min width for modal if needed
   */
  minWidth?: number,
  /**
   * On close buton clicked callback
   */
  onClose: () => void;
}

const Modal: React.FunctionComponent<IModalProps> = (props) => {
  const { id, header, footer, children, minWidth, onClose } = props;

  return <div id={id} className={Styles.modal}>
    <div aria-modal="true" className={Styles.modalInner} role="dialog" style={{ minWidth }}>
      {header && <header className={Styles.header}>
        {typeof header === "string" ? (<Text size="lead" strong>{header}</Text>) : header}
        <Button icon="cross" appearance="tertiary" outline onClick={() => onClose()} />
      </header>}
      <div className={Styles.content}>
        {children}
      </div>
      {footer && <footer className={Styles.footer}>
        {footer}
      </footer>}
    </div>
  </div>
}

Modal.displayName = "Modal";

Modal.defaultProps = {
  id: undefined,
  header: undefined,
  footer: undefined,
  onClose: () => { }
};

export default Modal;