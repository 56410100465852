const MAXIMUM_NUMBER_OF_INITIALS = 3;
const PREFERRED_NUMBER_OF_INITIALS = 2;

const EMAIL_LIKE_REGEX = /(.+)@.+\..+/;
const ARABIC_TEXT_REGEX = /[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/;
const AMERICAN_SUFFIXES = /^[SsJj]r\.?$/;
const INLINE_COMMENTS = /\s*\(.+\)/;
const INLINE_NONLATIN_CHARS = /[^\w\s]|_/gi;

/**
 * Options for `getInitials()`.
 */
interface IFindInitialsOptions {
  /**
   * Indicates whether e-mail address should be _detected_ and a more appropriate set of rules should be applied.
   * Default is `true`.
   */
  readonly detectEmail?: boolean;
  /**
   * Indicates whether _unsupported_ alphabets should be _detected_. When the name contains any character in one of the
   * unsupported alphabets then an empty string is returned. Default is `true`.
   */
  readonly detectUnsupportedAlphabets?: boolean;
  /**
   * Indicates whether American suffixes (like Sr and Jr) should be stripped from the calculation of the initials. Note that
   * they're stripped only for _real_ names (not e-mails, see also @see detectEmail) and only if they appear at the end of the name.
   * Default is `true`.
   */
  readonly stripAmericanSuffixes?: boolean;
  /**
   * Indicates whether inline comments (any text in parenthesis, not at the beginning of the name) should be stripped. For example
   * _Aubrey Drake Graham (aka "Drake")_ is treated as _Aubrey Drake Graham_. It is not applied when the name is detected as e-mail
   * (see also @see detectEmail). Default is `true`.
   */
  readonly stripComments?: boolean;
  /**
   * Indicates whether punctuation (and any other not alphanumeric character) should be stripped from the name before
   * calculating the initials. Note that this also strips any non Latin character then it cannot be used when other
   * alphabets need to be supported. Default is `false`.
   */
  readonly stripPunctuation?: boolean;
  /**
   * Maximum length (in letters, not code units) of the extracted string. This value is used only for _real_ names (not when the text
   * is detected as e-mail address, see also @see detectEmail) and it indicates the maximum length, if the name contains fewer words then
   * a shorter string might be returned. Note that if the name consists of a single word then each letter is considered a word and
   * `preferredLength` letters are returned (because of this - to support languages where spaces are optional - mononym are effectively
   * not supported). Default value is 3.
   */
  readonly maximumLength?: number;
  /**
   * Preferred length (in letters, not code units) of the extracted string. This is the value used for e-mails (see also @see detectEmail)
   * and when the name consists of a single word. Default value is 2.
   */
  readonly preferredLength?: number;
}

/**
 * Extract the initials for the given name.
 * @param name - Name from which the initials will be extrcted. An _initial_ is considered the first letter
 * of any word (at the beginning of the string or separated from the previous one with one or more space characters)/
 * 
 * @param options - Options for extracting the initials, if omitted then all the default values are used.
 * @returns - The initials for the specified name, calculated according the the given options or an empty string if
 * `null` or `undefined` is the input.
 * The length of this string is at most `IFindInitialsOptions.maximumLength` letters (not code units).
 */
export function getInitials(name: string | null | undefined, options?: IFindInitialsOptions) {
  if (!canExtractInitials(name, options)) {
    return "";
  }

  if (options?.detectEmail !== false && name.match(EMAIL_LIKE_REGEX)) {
    return getInitialsFromEmail(name, options);
  }

  return getInitialsFromName(stripUnwantedCharacters(name, options), options);
}

function canExtractInitials(name: string | null | undefined, options?: IFindInitialsOptions) {
  // Empty stirng, null or undefined? It could happen (for example) during registration for
  // a newly created account when do no not have anything about the user.
  if (!name) {
    return false;
  }

  // For Arabic text we really can't simply extract a few characters
  if (options?.detectUnsupportedAlphabets !== false && name.match(ARABIC_TEXT_REGEX)) {
    return false;
  }

  return true;
}

function getInitialsFromEmail(name: string, options?: IFindInitialsOptions) {
  // We do not care about the e-mail host and its domain, only the local part
  // identifies the user (usually, at least).
  // Note that we do NOT handle an input like "Adriano Repetti <adriano.repetti@example.com>"
  // because we do not have a scenario where we get it (then we'll produce "Aar").
  const localPart = name.match(EMAIL_LIKE_REGEX)[1];
  const words = splitWords(localPart, /\W+/);

  if (words.length === 1) {
    return Array.from(localPart).slice(0, options?.preferredLength ?? PREFERRED_NUMBER_OF_INITIALS).join("");
  }

  // E-mail addresses are too fancy to try to pick one character here
  // and another there, just scan them sequentially.
  return getInitialsFromWords(words, options?.preferredLength ?? PREFERRED_NUMBER_OF_INITIALS, "sequential").toLocaleUpperCase();
}

function getInitialsFromName(name: string, options?: IFindInitialsOptions) {
  // In most Western names we use space to separate between first/last
  // and we want to pick initials from both.
  let words = splitWords(name, /\s+/);

  // If we have to strip out American suffixes then we assume:
  // To have a suffix they at least have first and last name (no mononyms).
  // * They have just one of them.
  // * It's at the end of the name.
  if (words.length > 2 && options?.stripAmericanSuffixes !== false && words[words.length - 1].match(AMERICAN_SUFFIXES)) {
    words = words.slice(0, -1);
  }

  // If it is a single word then:
  // * it could be that the name is written without spaces (pretty common in many
  // Eastern languages). For example we expect to produce "김아" for "김아라".
  // * it is a mononym and we _should_ extract a single initial however this is so
  // uncommon that we simply ignore it.
  // We need to enumerate the string because some characters might be more than one code unit (like "𠀑").
  if (words.length === 1) {
    return Array.from(name).slice(0, options?.preferredLength ?? PREFERRED_NUMBER_OF_INITIALS).join("");
  }

  // We do not know exactly where the last name finishes (in some cases there is more
  // than one character) and we do not even know which one comes first so we pick one letter from the head
  // and one from the tail (and repeat).
  return getInitialsFromWords(words, options?.maximumLength ?? MAXIMUM_NUMBER_OF_INITIALS, "interleaved");
}

function stripUnwantedCharacters(name: string, options?: IFindInitialsOptions) {
  let result = name;

  if (options?.stripComments !== false) {
    result = result.replace(INLINE_COMMENTS, "");
  }

  if (options?.stripPunctuation) {
    result = result.replace(INLINE_NONLATIN_CHARS, "");
  }

  return result;
}

function splitWords(name: string, splitter: RegExp) {
  return name.split(splitter).map((w) => w.trim()).filter((w) => !!w);
}

function getInitialsFromWords(words: readonly string[], count: number, strategy: "sequential" | "interleaved") {
  // We do not know the specific of the locale then
  // we cannot handle digraphs (and trigraphs) properly: "රිශ්නි ගිනිගේ" produces
  // "රග" instead of "රිගි" (and the same goes, for example, in Dutch for IJ like IJsselmeer).
  function pickInitials(w: readonly string[]) {
    return w.reduce((result, word) => {
      return [...result, Array.from(word)[0]];
    }, []).join("");
  }

  // Simply goes left to right and pick the first initial for each word.
  if (strategy === "sequential") {
    return pickInitials(words.slice(0, count));
  }

  // We pick one grapheme from each word, one at the beginning and one at the
  // end until we're done. The basic assumption is that this is a Western name
  // and last name comes...last. We try to skip middle names and - again - because
  // we do not know how many words make the last name we assume it's just one.
  // "Jean-Pierre Adams" produces "JA" and "Jean Pierre Polnareff" produces "JP"
  // however for "Mary Jane Smith" we produce "MS" even if "Mary Jane" is usually
  // considered the given name. The same goes for family names when they have more
  // than one word: "Muhammad Nisar Ahmed" produces "MA" instead of "MN" (if count is 2).
  // Note how we split the list in the middle (preferring the "last name" half for odd numbers)
  // and we pick one on each side (keeping the left to right ordering inside each half).
  const fromLeft = Math.floor(count / 2);
  const fromRight = Math.min(words.length - fromLeft, Math.ceil(count / 2));
  return pickInitials(words.slice(0, fromLeft)) + pickInitials(words.slice(-fromRight));
}
