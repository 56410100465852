import React from "react";
import PropTypes from "prop-types";

import Styles from "./row.module.scss";

// Utilities
import { ClassNameMaker, Tackons } from "utils";

export function Row(props) {
  const {
    appearance,
    children,
    density,
    expanded,
    expansionContent,
    hover,
    onClick,
    tackons
  } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.wrapper,
    Styles[`appearance-${appearance}`],
    hover && Styles[`hoverable`]
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return (
    <div className={className}>
      <div
        className={Styles.container}
        style={style}
        role="row"
        onClick={onClick}
      >
        {Array.isArray(children)
          ? children.map((c, i) => {
            return c && React.cloneElement(c, { key: i, density: density });
          })
          : children && React.cloneElement(children, { density: density })}
      </div>
      {expanded && <div className={Styles.panel}>{expansionContent}</div>}
    </div>
  );
}

Row.displayName = "Row";

Row.propTypes = {
  /**
   * Appearance styling
   */
  appearance: PropTypes.oneOf([
    "disabled",
    "default",
    "active",
    "info",
    "success",
    "warning",
    "danger"
  ]),
  /**
   * Content to display inside the row
   */
  children: PropTypes.node.isRequired,
  /**
   * How much padding inside the row
   */
  density: PropTypes.oneOf(["compact", "default", "comfortable"]),
  /**
   * Show or hide the expansion content
   */
  expanded: PropTypes.bool,
  /**
   *
   */
  expansionContent: PropTypes.node,
  /**
   * Highlight rows on hover
   */
  hover: PropTypes.bool,
  /**
   * Callback when row is clicked
   */
  onClick: PropTypes.func,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string
};

Row.defaultProps = {
  appearance: "default",
  children: undefined,
  density: "default",
  expanded: false,
  expansionContent: undefined,
  hover: false,
  onClick: () => { },
  tackons: undefined
};

export default Row;
