import React from "react";
import PropTypes from "prop-types";
import IconPaths from "components/icon/iconPaths.generated";
import { breakpointKeys } from "enums";

import Icon from "components/icon";
import Badge from "components/badge";
import Heading from "components/heading";

//Utilities
import { ClassNameMaker, Tackons } from "utils";

//Styles
import Styles from "./navigation.module.scss";

// Get list of valid icons
const BreakpointsEnum = Object.values(breakpointKeys);
const IconsEnum = Object.keys(IconPaths);

function Navigation(props) {
  const {
    activeItem,
    activeOrange,
    collapsed,
    id,
    items,
    maxCount,
    size,
    spacing,
    title,
    tackons,
  } = props;

  const collapsedClass =
    collapsed && typeof collapsed == "string"
      ? `collapsed-${collapsed}`
      : "collapsed";

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    collapsed && Styles[collapsedClass],
    spacing && Styles[`spacing-${spacing}`],
    activeOrange && Styles[activeOrange],
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  //Output

  return (
    <nav id={id} className={className} style={style}>
      {title && (
        <div className={Styles.title}>
          <Heading element="h3">{title}</Heading>
        </div>
      )}
      <ul className={Styles.list}>
        {items &&
          items.map((o, i) => {
            const active =
              (typeof activeItem === "string" && activeItem === o.id) ||
              (typeof activeItem === "number" && activeItem === i);
            const itemClass = ClassNameMaker([
              Styles.item,
              active && Styles.active,
              o.disabled && Styles.disabled,
            ]);
            const activeItemStyle = active
              ? getActiveItemStyle(activeOrange)
              : null;
            const iconSize = getIconSize(size);
            return (
              <li id={o.id} className={itemClass} key={i}>
                <a
                  href={o.url ? o.url : "#"}
                  className={Styles.link}
                  onClick={o.onClick}
                  aria-label={o.ariaLabel}
                  data-tooltip={o.label}
                  style={activeItemStyle}
                >
                  <div className={Styles.content}>
                    {o.icon && (
                      <span className={Styles.icon}>
                        <Icon
                          width={iconSize}
                          height={iconSize}
                          name={o.icon}
                          decorative
                        />
                      </span>
                    )}
                    <span
                      className={`${Styles.label} ${size ? Styles[size] : ""}`}
                    >
                      {o.label}
                    </span>
                  </div>
                  {o.count && o.count > 0 ? (
                    <div className={Styles.count}>
                      <Badge
                        size="small"
                        label={o.count > maxCount ? `${maxCount}+` : o.count}
                        appearance="info"
                      />
                    </div>
                  ) : null}
                </a>
              </li>
            );
          })}
      </ul>
    </nav>
  );
}

function getIconSize(size) {
  if (size === "large") {
    return "24px";
  }

  return "16px";
}

function getActiveItemStyle(activeOrange): any {
  const color = activeOrange ? "#ef7900" : "#06679b";
  return { color };
}

Navigation.displayName = "Navigation";

Navigation.propTypes = {
  /**
   * Pass either a matching id, or a 0 based item index to set an item as active
   */
  activeItem: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Change active item color to $brand-orange(#ef7900)
   */
  activeOrange: PropTypes.bool,
  /**
   * Reduce the navigation presentation to icon only with tooltip labels. Collapsed accepts a boolean, or a breakpoint enum.
   * If an enum is supplied the navigation will be collapsed *below* the given breakpoint
   */
  collapsed: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(BreakpointsEnum),
  ]),
  /**
   * Unique identifier
   */
  id: PropTypes.string,
  /**
   * Elements making up the navigation
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ariaLabel: PropTypes.string,
      label: PropTypes.string.isRequired,
      id: PropTypes.string,
      icon: PropTypes.oneOf(IconsEnum),
      count: PropTypes.number,
      onClick: PropTypes.func,
      url: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  /**
   * Set a threshold for all item counts. Above this number the maximum number will be displayed with the '+' symbol appended.
   */
  maxCount: PropTypes.number,
  /**
   * Controls the size of icon and label
   */
  size: PropTypes.oneOf(["large", "medium"]),
  /**
   * Controls the spacing between the navigation items
   */
  spacing: PropTypes.oneOf(["tight", "default", "loose"]),
  /**
   * Title to appear above the navigation
   */
  title: PropTypes.string,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
};

Navigation.defaultProps = {
  activeItem: undefined,
  activeOrange: undefined,
  collapsed: undefined,
  id: undefined,
  items: undefined,
  maxCount: undefined,
  spacing: "default",
  title: undefined,
  tackons: undefined,
};

export default Navigation;
