// -----------------------------------------------------------------------------
// Stack
// -----------------------------------------------------------------------------

//React
import React from "react";
import PropTypes from "prop-types";

//Utilities
import { ClassNameMaker, Tackons } from "utils";

//Styles
import Styles from "./stack.module.scss";

function Stack(props) {
  const {
    align,
    children,
    id,
    justify,
    reverse,
    spacing,
    tackons,
    vertical,
    wrap
  } = props;
  //Variables

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    align && Styles[`align-${align}`],
    justify && Styles[`justify-${justify}`],
    spacing && Styles[`spacing-${spacing}`],
    vertical && Styles["vertical"],
    reverse && Styles["reverse"],
    !wrap && Styles["noWrap"]
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  //Output
  return (
    <div id={id} className={className} style={style}>
      {children.length > 1
        ? children.map((c, i) => {
            return (
              <div className={Styles.item} key={i}>
                {React.cloneElement(c)}
              </div>
            );
          })
        : children}
    </div>
  );
}

Stack.displayName = "Stack";

Stack.propTypes = {
  /**
   * Alignment of items in the cross axis of the layout direction
   */
  align: PropTypes.oneOf(["start", "end", "center", "fill", "baseline"]),
  /**
   * Content to display inside the stack
   */
  children: PropTypes.node,
  /**
   * Unique label to identify this element in the dom
   */
  id: PropTypes.string,
  /**
   * Alignment of the items in the main axis of the layout direction.
   */
  justify: PropTypes.oneOf([
    "start",
    "end",
    "center",
    "spaceBetween",
    "spaceAround",
    "fill",
    "fillEvenly"
  ]),
  /**
   * Reverse the order of the items in the stack
   */
  reverse: PropTypes.bool,
  /**
   * Each child element is wrapped in container with margins to space the items.
   * Spacing controls the size of that margin
   */
  spacing: PropTypes.oneOf([
    "extraTight",
    "tight",
    "default",
    "loose",
    "extraLoose",
    "none"
  ]),
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
  /**
   * Orient the items in the stack vertically
   */
  vertical: PropTypes.bool,
  /**
   * Set whether child elements are allowed to wrap onto a new line
   */
  wrap: PropTypes.bool
};

Stack.defaultProps = {
  align: "fill",
  children: undefined,
  id: undefined,
  justify: "start",
  reverse: false,
  spacing: "default",
  tackons: undefined,
  vertical: false,
  wrap: true
};

export default Stack;
