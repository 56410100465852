import React from "react";

// Styles

// Utilities
import { ClassNameMaker, Tackons } from "utils";
import Styles from "./spinner.module.scss";

interface ISpinnerProps {
  /** Spinner type */
  appearance: "speech" | "dots" | "wheel";
  /** Color scheme for the spinner */
  color: "orange" | "shade" | "white";
  /** Spinner is always square - size can be adjusted */
  size: "small" | "medium" | "large";
  /** Functional CSS add-ons to override margin, position etc. */
  tackons;
}

/**
 * The Spinner component indicates to the user that an action is being process. Spinner should be used where more meangingful progress indicators are not possible e.g. skeleton loading presentation.
 */

function Spinner(props: ISpinnerProps) {
  const { appearance, color, size, tackons } = props;

  // Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    appearance && Styles[appearance],
    color && Styles[color],
    size && Styles[size],
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return (
    <div className={className} style={style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
        className={Styles.svg}
      >
        {appearance === "speech" && (
          <g fill="none" fillRule="evenodd">
            <circle
              cx="6"
              cy="10"
              r="2"
              fill="currentColor"
              className={Styles.speechdot1}
            />
            <circle
              cx="12"
              cy="10"
              r="2"
              fill="currentColor"
              className={Styles.speechdot2}
            />
            <circle
              cx="18"
              cy="10"
              r="2"
              fill="currentColor"
              className={Styles.speechdot3}
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M7.51937515,19 L3.001,19 C1.34394722,19 1.77635684e-15,17.6565167 1.77635684e-15,16 L1.77635684e-15,3 C1.77635684e-15,1.34348331 1.34394722,0 3.001,0 L21,0 C22.6570168,0 24,1.34344712 24,3 L24,16 C24,17.6565529 22.6570168,19 21,19 L16.4806248,19 L12.7808688,23.624695 C12.3805435,24.1251017 11.6194565,24.1251017 11.2191312,23.624695 L7.51937515,19 Z M12,21.12 L15.2448842,17.1374093 C15.4361746,16.9026302 15.725786,16.7659585 16.032,16.7659585 L21.072,16.7659585 C21.628711,16.7659585 22.08,16.3226958 22.08,15.776228 L22.08,2.90973057 C22.08,2.36326274 21.628711,1.92 21.072,1.92 L2.929008,1.92 C2.37194907,1.92 1.92,2.36360457 1.92,2.90973057 L1.92,15.776228 C1.92,16.322354 2.37194907,16.7659585 2.929008,16.7659585 L7.968,16.7659585 C8.27421399,16.7659585 8.5638254,16.9026302 8.75511576,17.1374093 L12,21.12 Z"
              className={Styles.bubble}
            />
          </g>
        )}
        {appearance === "dots" && (
          <g fill="none" fillRule="evenodd">
            <circle
              cx="4"
              cy="12"
              r="2.5"
              fill="currentColor"
              className={Styles.dot1}
            />
            <circle
              cx="12"
              cy="12"
              r="2.5"
              fill="currentColor"
              className={Styles.dot2}
            />
            <circle
              cx="20"
              cy="12"
              r="2.5"
              fill="currentColor"
              className={Styles.dot3}
            />
          </g>
        )}
        {appearance === "wheel" && (
          <path
            d="M12.0853405,0 C12.8269571,0 13.4281561,0.596953667 13.4281561,1.33333333 C13.4281561,2.069713 12.8269571,2.66666667 12.0853405,2.66666667 C6.89402444,2.66666667 2.68563123,6.84534236 2.68563123,12 C2.68563123,17.1546576 6.89402444,21.3333333 12.0853405,21.3333333 C14.6029674,21.3333333 16.9601973,20.3478422 18.7106849,18.6207002 C19.2367467,18.1016543 20.0869662,18.1043304 20.6097035,18.6266774 C21.1324406,19.1490244 21.1297455,19.9932401 20.6036837,20.512286 C18.3550168,22.7309633 15.3195646,24 12.0853405,24 C5.41079127,24 0,18.627417 0,12 C0,5.37258302 5.41079127,0 12.0853405,0 Z"
            className={Styles.segment}
          />
        )}
      </svg>
    </div>
  );
}

Spinner.displayName = "Spinner";

Spinner.defaultProps = {
  appearance: "dots",
  color: "orange",
  size: "medium",
  tackons: undefined,
};

export default Spinner;
