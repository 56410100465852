import React from "react";
import PropTypes from "prop-types";

import { breakpointKeys } from "enums";

// Styles
import Styles from "./row.module.scss";

//Utilities
import { ClassNameMaker, Tackons } from "utils";

const breakpoints = Object.values(breakpointKeys);

function Row(props) {
  const {
    debug,
    id,
    reverse,
    start,
    center,
    end,
    top,
    middle,
    bottom,
    around,
    between,
    tagName,
    children,
    tackons
  } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.row,
    debug && Styles["debug"],
    reverse && Styles[`reverse-${reverse}`],
    start && Styles[`start-${start}`],
    center && Styles[`center-${center}`],
    end && Styles[`end-${end}`],
    top && Styles[`top-${top}`],
    middle && Styles[`middle-${middle}`],
    bottom && Styles[`bottom-${bottom}`],
    around && Styles[`around-${around}`],
    between && Styles[`between-${between}`]
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return React.createElement(
    tagName || "div",
    {
      className: className,
      style: style,
      id: id
    },
    children
  );
}

Row.propTypes = {
  /**
   * Unique indentifier in the DOM
   */
  id: PropTypes.string,
  /**
   * Overlay a visual indication of the column and gutter boundaries.
   */
  debug: PropTypes.bool,
  /**
   * Reverse the column order above a chosen breakpoint.
   */
  reverse: PropTypes.oneOf(breakpoints),
  /**
   * Horizontally align columns to the start of the row above a chosen breakpoint when the column count does not occupy the whole row.
   */
  start: PropTypes.oneOf(breakpoints),
  /**
   * Horizontally align columns in the center of the row above a chosen breakpoint when the column count does not occupy the whole row.
   */
  center: PropTypes.oneOf(breakpoints),
  /**
   * Horizontally align columns to the end of the row above a chosen breakpoint when the column count does not occupy the whole row.
   */
  end: PropTypes.oneOf(breakpoints),
  /**
   * Vertically align columns to the top of the horizontal axis above a chosen breakpoint when the column content is unequal in height
   */
  top: PropTypes.oneOf(breakpoints),
  /**
   * Vertically align columns to the middle of the horizontal axis above a chosen breakpoint when the column content is unequal in height
   */
  middle: PropTypes.oneOf(breakpoints),
  /**
   * Vertically align columns to the bottom of the horizontal axis above a chosen breakpoint when the column content is unequal in height
   */
  bottom: PropTypes.oneOf(breakpoints),
  /**
   * Distribute any remaining space unused by columns above a chosen breakpoint around the columns
   */
  around: PropTypes.oneOf(breakpoints),
  /**
   * Distribute any remaining space unused by columns above a chosen breakpoint between the columns
   */
  between: PropTypes.oneOf(breakpoints),
  /**
   * Which element should be used to render the row
   */
  tagName: PropTypes.string,
  /**
   * Content to display - this should usually be <Grid.Col/> child components.
   */
  children: PropTypes.node,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string
};

Row.defaultProps = {
  children: undefined,
  tackons: undefined,
  between: undefined,
  around: undefined,
  bottom: undefined,
  middle: undefined,
  top: undefined,
  end: undefined,
  center: undefined,
  start: undefined,
  reverse: undefined,
  id: undefined,
  debug: false,
  tagName: "div"
};

export default Row;
