import React from "react";

import { Tackons } from "utils";

import Styles from "./progress.module.scss";

interface IProgressProps {
  height?: number | string;
  borderRadius?: number | string;
  value?: number;
  color?: string;
  tackons?: string;
}

const Progress: React.FC<IProgressProps> = (props) => {
  const { color, height, borderRadius, value, tackons } = props;
  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return (
    <div
      className={Styles.container}
      style={{ ...style, height, borderRadius }}
    >
      <div
        className={Styles.bar}
        style={{ backgroundColor: color, height, width: `${value}%` }}
      ></div>
    </div>
  );
};

Progress.displayName = "Progress";

Progress.defaultProps = {
  value: 0,
  height: 8,
  borderRadius: 0,
};

export default Progress;
