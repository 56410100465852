import React from "react";
import Styles from "./toast.module.scss";
import Card from "../card";
import Icon from "../icon";
import Button from "../button";
import PropTypes from "prop-types";
import IconPaths from "../icon/iconPaths.generated";
import { ClassNameMaker } from "../../utils";

const IconsEnum = Object.keys(IconPaths);

const Toast = ({ children, onDismiss, appearance, icon }) => {
    return (
        <Card tackons="p2">
            <div className={Styles.toastWrapper}>
                <div className={Styles[appearance]}>
                    <div className={ClassNameMaker([Styles.icon, icon ? Styles.custom : null])}>
                        <Icon name={icon || iconName(appearance)} decorative={true} />
                    </div>
                </div>
                {children}
                <div className={Styles.closeButtonWrapper}>
                    <Button icon="cross" onClick={() => onDismiss()} />
                </div>
            </div>
        </Card>
    );
};

const iconName = (appearance) => ({
    success: "check-circle",
    warning: "exclamation-triangle",
    error: "exclamation-circle",
    info: "info-circle"
}[appearance]);

Toast.propTypes = {
    /**
     * Indicate visual state.
     */
    appearance: PropTypes.oneOf([
        "success",
        "warning",
        "error",
        "info"
    ]).isRequired,
    /**
     * Content to display inside the toast
     */
    children: PropTypes.node.isRequired,
    /**
     * Callback when the close button is clicked
     */
    onDismiss: PropTypes.func,
    /**
     * The name of the icon to display in the toast
     */
    icon: PropTypes.oneOf(IconsEnum)
};

Toast.defaultProps = {
    appearance: "info",
    children: undefined,
    onDismiss: () => { },
    icon: undefined
};

export default Toast;
