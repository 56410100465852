const MAIL_TO_SCHEMA = "mailto:";

function isPathAbsolute(url: string) {
    // This is a bit of a simplification but let's assume
    // that paths are always valid (but possibly relative).
    try {
        return !!(new URL(url));
    } catch {
        return false;
    }
}

function combineRelativePathWithCurrentOrigin(relativePath: string) {
    const { origin, search, hash } = window.location;
    return `${origin}${relativePath}${search}${hash}`;
}

function isEmailAddress(text: string) {
    // Yep, this is super naive but a proper regex for ANY valid e-mail address is super complex
    return text.includes("@");
}

export function makeUrlAbsolute(url: string) {
    if (isEmailAddress(url)) {
        return url.startsWith(MAIL_TO_SCHEMA) ? url : `${MAIL_TO_SCHEMA}${url}`;
    }

    return isPathAbsolute(url) ? url : combineRelativePathWithCurrentOrigin(url);
}

