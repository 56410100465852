import React from "react";
import PropTypes from "prop-types";

import Icon from "components/icon";
import Button from "components/button";
import TextContainer from "components/textContainer";
import Heading from "components/heading";

// Styles
import Styles from "./computerSays.module.scss";

// Utilities
import { ClassNameMaker, Tackons } from "utils";

function ComputerSays(props) {
  const {
    // presentation
    action,
    appearance,
    icon,
    message,
    title,

    tackons,
  } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    Styles[`appearance-${appearance}`],
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return (
    <div className={className} style={style}>
      {icon && (
        <div className={Styles.icon}>
          <Icon name={icon} width="32" height="32" decorative />
        </div>
      )}
      <TextContainer align="center">
        {title && <Heading element="h2">{title}</Heading>}
        <div className={Styles.message}>{message}</div>
      </TextContainer>
      {action && (
        <div className={Styles.actions}>
          <Button appearance="primary" onClick={action.onClick}>
            {action.label}
          </Button>
        </div>
      )}
    </div>
  );
}

ComputerSays.displayName = "ComputerSays";

ComputerSays.propTypes = {
  /**
   * Label and action for the button
   */
  action: PropTypes.exact({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  /**
   * Appearance modifies the colour scheme of the message.
   */
  appearance: PropTypes.oneOf(["error", "warning", "info", "success"])
    .isRequired,
  /**
   * Icon
   */
  icon: PropTypes.string,
  /**
   * Message title
   */
  title: PropTypes.string,
  /**
   * Message content
   */
  message: PropTypes.string.isRequired,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
};

ComputerSays.defaultProps = {
  appearance: undefined,
  title: undefined,
  icon: undefined,
  message: undefined,
  action: undefined,
  tackons: undefined,
};

export default ComputerSays;
