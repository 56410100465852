import React, { useMemo } from "react";
import { makeUrlAbsolute } from "./utils";

type TinyMarkupProps = {
    readonly text: string;
    readonly model?: object;
    readonly children?: never;
};

const TinyMarkup: React.FC<TinyMarkupProps> = props => {
    const content = useMemo(() => {
        // Model is redeclared to keep `any` private, we only want objects.
        const model: any = props.model ?? {};

        // Frist step: replace placeholders with their content.
        const unsafeHtml = props.text.replace(/(\{\w+\})/g, match => model[match.substr(1, match.length - 2)]);

        // Second step: replace links with the appropriate components.
        return unsafeHtml.split(/(\[.+\]\(.+\))/g).map((part, index) => {
            const match = part.match(/\[(.+)\]\((.+)\)/);
            if (match === null) {
                return <span key={index}>{part}</span>;
            }

            return (
                <a
                    key={index}
                    href={makeUrlAbsolute(match[2])}
                    target="__blank"
                    rel="noreferrer noopener"
                >
                    {match[1]}
                </a>
            );
        });
    }, [props.text, props.model]);

    return <>{content}</>;
};

TinyMarkup.displayName = "TinyMarkup";

export default TinyMarkup;
