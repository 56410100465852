import React from "react";
import PropTypes from "prop-types";

// Styles
import Styles from "../card.module.scss";

// Utilities
import { ClassNameMaker, Tackons } from "utils";

function Section(props) {
  const { children, muted, tackons } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([Styles.section, muted && Styles["muted"]]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}

Section.displayName = "Section";

Section.propTypes = {
  /**
   * Content to display inside the cell
   */
  children: PropTypes.node,
  /**
   * Section has a muted background
   */
  muted: PropTypes.bool,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string
};

Section.defaultProps = {
  children: undefined,
  muted: false,
  tackons: undefined
};

export default Section;
