import React from "react";
import PropTypes from "prop-types";

import Icon from "components/icon";
// Styles
import Styles from "./headerCell.module.scss";

// Utilities
import { ClassNameMaker, StyleMaker } from "utils";

export function HeaderCell(props) {
  const {
    align,
    children,
    direction,
    display,
    grow,
    id,
    justify,
    maxWidth,
    minWidth,
    onClick,
    shrink,
    sortable,
    tackons
  } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    Styles[`display-${display}`],
    Styles[`grow-${grow}`],
    Styles[`shrink-${shrink}`],
    Styles[`justify-${justify}`],
    Styles[`align-${align}`]
  ]);

  let style = StyleMaker(tackons, [
    { minWidth: minWidth },
    { maxWidth: maxWidth }
  ]);

  return (
    <div id={id} className={className} style={style} role="columnheader">
      <button onClick={onClick} className={Styles.sortButton}>
        <span>{children}</span>
        {sortable && direction === "desc" ? (
          <span className={Styles.sortIcon}>
            <Icon name="caret-down" width="16" height="16" decorative />
          </span>
        ) : null}
        {sortable && direction === "asc" ? (
          <span className={Styles.sortIcon}>
            <Icon name="caret-up" width="16" height="16" decorative />
          </span>
        ) : null}
      </button>
    </div>
  );
}

HeaderCell.displayName = "HeaderCell";

HeaderCell.propTypes = {
  /**
   * Change the alignment of the text within
   */
  align: PropTypes.oneOf(["top", "middle", "bottom"]),
  /**
   * Content to display inside the cell
   */
  children: PropTypes.node,
  /**
   * Sort ascending / descending
   */
  direction: PropTypes.oneOf(["asc", "desc"]),
  /**
   * Only show this cell above the selected breakpoint
   */
  display: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  /**
   * Flex growth factor relative to other columns.  By default all cells
   * will expand to fill the space, but the distribution will very according
   * to the grow value.
   */
  grow: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
  /**
   * Uniquely identify this element in the DOM
   */
  id: PropTypes.string,
  /**
   * Change the justification of the text within
   */
  justify: PropTypes.oneOf(["left", "center", "right"]),
  /**
   * Constrain the maximum width of the cell. Pass a positive integer to set a maximum width in pixels.
   * Pass a string (e.g. maxWidth="10%") to use other units.
   */
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Set a minimum width for the cell. Pass a positive integer to set a minimum width in pixels.
   * Pass a string (e.g. minWidth="2rem") to use other units.
   */
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Callback when sorting button is clicked
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Flex shrink factor relative to other columns.  By default all cells
   * will shrink when insufficient space is available, but the distribution will vary
   * according to the shrink value. Use shrink 0 to stop a cell from shrinking.
   *
   */
  shrink: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
  /**
   * Is the column on which data is sorted
   */
  sortable: PropTypes.bool,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string
};

HeaderCell.defaultProps = {
  align: "middle",
  children: undefined,
  direction: undefined,
  display: undefined,
  grow: undefined,
  id: undefined,
  justify: "left",
  maxWidth: undefined,
  minWidth: undefined,
  onClick: () => { },
  shrink: undefined,
  sortable: false,
  tackons: undefined
};

export default HeaderCell;
