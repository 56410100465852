// -----------------------------------------------------------------------------
// Button Group
// -----------------------------------------------------------------------------

//React
import React from "react";
import PropTypes from "prop-types";
import { childrenOfType } from "airbnb-prop-types";

import Button from "components/button";

//Utilities
import { ClassNameMaker, Tackons } from "utils";

//Styles
import Styles from "./buttonGroup.module.scss";

/**
 *
 * Button group distributes buttons in useful arrangements
 */
function ButtonGroup(props) {
  const { ariaLabel, children, fullWidth, justify, segmented, tackons } = props;
  //Variables

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.group,
    fullWidth && Styles["full-width"],
    segmented && Styles["segmented"],
    justify && Styles[`justify-${justify}`]
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  //Output
  if (React.Children.count(children) === 1) {
    console.warn("Button group requires more than one <Button> as children");
    return children;
  }
  if (segmented) {
    return (
      <div
        className={className}
        style={style}
        role="group"
        aria-label={ariaLabel}
      >
        {children.map((c, i) => {
          let segment = "intermediate";
          if (i === children.length - 1) {
            segment = "last";
          }
          if (i === 0) {
            segment = "first";
          }
          return React.cloneElement(c, { key: i, segment: segment });
        })}
      </div>
    );
  }
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
}

ButtonGroup.displayName = "ButtonGroup";

ButtonGroup.propTypes = {
  /**
   * Visually hidden button label for screen-readers
   */
  ariaLabel: PropTypes.string,
  /**
   * Children must all be <Button> components
   */
  children: childrenOfType(Button),
  /**
   * Buttons will stretch/shrink to fill the width of the container
   */
  fullWidth: PropTypes.bool,
  /**
   * Align buttons in the container
   */
  justify: PropTypes.oneOf(["start", "center", "end", "between", "around"]),
  /**
   * Join buttons together to make a segmented button collection
   */
  segmented: PropTypes.bool,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string
};

ButtonGroup.defaultProps = {
  ariaLabel: undefined,
  children: undefined,
  fullWidth: false,
  justify: "start",
  segmented: false,
  tackons: undefined
};

export default ButtonGroup;
