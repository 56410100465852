// -----------------------------------------------------------------------------
// Banner
// -----------------------------------------------------------------------------

//React
import React from "react";
import PropTypes from "prop-types";

import Icon from "components/icon";
import Button from "components/button";
import Heading from "components/heading";
import IconPaths from "../icon/iconPaths.generated";

//Utilities
import { ClassNameMaker, Tackons } from "utils";

//Styles
import Styles from "./banner.module.scss";

/**
 *
 * Banner is used to notify users of changes that have taken place. Colour is used to indicate priority,
 * some banners may also have follow up actions; it may also be possible to 'dismiss' the alert so that they
 * are not reminded again.
 *
 */

const IconsEnum = Object.keys(IconPaths);

function Banner(props) {
  const {
    appearance,
    children,
    customIcon,
    dismissable,
    id,
    noIcon,
    onDismiss,
    shadow,
    size,
    title,
    tackons,
  } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    appearance && Styles[appearance],
    !shadow && Styles["noShadow"],
    size && Styles[size],
  ]);

  let icon = customIcon || "info-circle";

  if (!customIcon) {
    switch (appearance) {
      case "success":
        icon = "check-circle";
        break;
      case "warning":
        icon = "exclamation-triangle";
        break;
      case "critical":
        icon = "exclamation-circle";
        break;
      default:
        break;
    }
  }

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  //Output
  return (
    <div
      className={className}
      id={id}
      style={style}
      role={appearance === "critical" ? "alert" : null}
    >
      {!noIcon && (
        <div className={Styles.icon}>
          <Icon name={icon} width="24" height="24" decorative />
        </div>
      )}
      <div className={Styles.content}>
        {title && (
          <header className={Styles.header}>
            <Heading element="h3">{title}</Heading>
          </header>
        )}
        {children}
      </div>
      {dismissable && (
        <div className={Styles.dismiss}>
          <Button
            icon="cross"
            ariaLabel="Close banner"
            appearance="tertiary"
            inheritColor
            size="small"
            onClick={(e) => onDismiss(e.currentTarget)}
          />
        </div>
      )}
    </div>
  );
}

Banner.displayName = "Banner";

Banner.propTypes = {
  /**
   * Color to indicate visual state. Appearance is also added as a visually hidden element for screenreaders
   */
  appearance: PropTypes.oneOf([
    "default",
    "info",
    "success",
    "warning",
    "critical",
  ]),
  /**
   * Content to display inside the stack
   */
  children: PropTypes.node.isRequired,
  /**
   * Custom icon name to show in banner
   */
  customIcon: PropTypes.oneOf(IconsEnum),
  /**
   * Should the banner have button to hide it?
   */
  dismissable: PropTypes.bool,
  /**
   * Unique label to identify this element in the dom
   */
  id: PropTypes.string,
  /**
   * Set to true to hide banner icon
   */
  noIcon: PropTypes.bool,
  /**
   * Callback when the hide button is clicked
   */
  onDismiss: PropTypes.func,
  /**
   * Toggle the shadow on component
   */
  shadow: PropTypes.bool,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
  /**
   * Text to appear in the heading
   */
  title: PropTypes.string,
};
Banner.defaultProps = {
  appearance: "default",
  children: undefined,
  dismissable: false,
  id: undefined,
  onDismiss: () => {},
  shadow: true,
  size: "medium",
  tackons: undefined,
  title: undefined,
};

export default Banner;
