// -----------------------------------------------------------------------------
// Button
// -----------------------------------------------------------------------------

// React
import * as React from "react";
import { useSpring, animated as a } from "react-spring";

import type { DisableableAction } from "types";

import Icon from "components/icon";

// Styles
import Styles from "./button.module.scss";

// Utilities
import { ClassNameMaker, Tackons } from "utils";

export interface IButtonProps extends DisableableAction {
  /**
   * Unique label to identify this element in the dom
   */
  id?: string;
  /**
   * Choose from one of the main button types.
   */
  appearance?: "primary" | "destructive" | "secondary" | "tertiary" | "link";
  /**
   * Optional CSS classes applied to the button.
   */
  className?: string;
  /**
   *  Button is in a loading state, shows a spinner icon, hides text and disables button
   */
  loading?: boolean;
  /**
   * callback when button is clicked
   */
  onClick?: (value: any) => void;
  /**
   * Content to display inside the button
   */
  children?: any;
  /**
   * ID of the element the button controls
   */
  ariaControls?: string;
  /**
   * Tells the screen-reader there's a open connected item
   */
  ariaExpanded?: boolean;
  /**
   * Visually hidden button label for screen-readers
   */
  ariaLabel?: string;
  /**
   * Tells the screen-reader the button is pressed
   */
  ariaPressed?: any;
  /**
   * Used when the button is responsible for triggering in-place popover content. Use the prop values "up" or "down" depending on where the popover will be positioned.
   */
  disclosure?: "up" | "down";
  /**
   * Reference to component root content
   */
  forwardedRef?: any;
  /**
   * Expand the width of the button to fill the parent container
   */
  fullWidth: boolean;
  /**
   * Pass the name of the icon, or pass an object containing
   * icon name and position.
   */
  icon?: any;
  /**
   * Makes the a button inherit text and icon colour from the parent.
   */
  inheritColor?: any;
  /**
   * Button is shown as an outline only
   */
  outline: boolean;
  /**
   * Used by `<ButtonGroup/>` to indicate sequence position and control border-radius
   * in a segmented collection
   */
  segment?: "first" | "intermediate" | "last";
  /**
   * Change the size of the button
   */
  size: "small" | "medium" | "large";
  /**
   * Indicates whether this button is of type "submit"
   */
  submit?: boolean;
  /**
   * Callback when button becomes focussed
   */
  onFocus?: any;
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons?: any;
}

/**
 *
 * Buttons are used to highlight actions to the user. They can be given visual importance using the appearance property and can be used to navigate or perform tasks.
 */
function Button(props: IButtonProps) {
  let {
    appearance,
    ariaControls,
    ariaExpanded,
    ariaLabel,
    ariaPressed,
    children,
    disabled,
    disclosure,
    forwardedRef,
    fullWidth,
    icon,
    id,
    inheritColor,
    outline,
    segment,
    size,
    submit,
    onClick,
    onFocus,
    tackons,
    loading,
  } = props;

  // override props if loading
  if (loading) {
    disabled = true;
  }
  // Allows utility classes to be applied
  const className = ClassNameMaker([
    props.className,
    Styles[appearance],
    Styles[size],
    outline && Styles.outline,
    fullWidth && Styles["full-width"],
    disclosure && Styles[`disclosure-${disclosure}`],
    segment && Styles[`segment-${segment}`],
    icon && Styles[`icon-${icon.position}`],
    inheritColor && Styles["inheritColor"],
    loading && Styles["loading"],
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  // set icon size based on button size
  let iconSize = "20px";
  switch (size) {
    case "small":
      iconSize = "14px";
      break;
    case "large":
      iconSize = "24px";
      break;
    default:
      break;
  }

  // spinner animation
  const spinnerAnimation = useSpring({
    from: { transform: "rotate(0deg)", position: "absolute" },
    to: async (next) => {
      while (loading) {
        await next({ transform: "rotate(360deg)" });
      }
    },
    config: { duration: 3200 },
    reset: true,
  });

  return (
    <button
      id={id}
      type={submit ? "submit" : "button"}
      style={style}
      onClick={onClick}
      onFocus={onFocus}
      aria-label={ariaLabel}
      aria-pressed={ariaPressed}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      className={className}
      disabled={disabled}
      ref={forwardedRef}
    >
      {loading && (
        <a.span className={Styles.spinner} style={spinnerAnimation}>
          <Icon name="spinner" width={iconSize} height={iconSize} decorative />
        </a.span>
      )}
      {icon && (
        <span className={Styles.icon}>
          <Icon
            name={icon.name ? icon.name : icon}
            width={iconSize}
            height={iconSize}
            decorative
          />
        </span>
      )}
      {children && <span className={Styles.label}>{children}</span>}
    </button>
  );
}

Button.defaultProps = {
  appearance: "secondary",
  ariaLabel: undefined,
  ariaControls: undefined,
  ariaExpanded: undefined,
  ariaPressed: false,
  children: undefined,
  disabled: false,
  loading: false,
  disclosure: undefined,
  fullWidth: false,
  icon: undefined,
  id: undefined,
  inheritColor: false,
  onClick: () => { },
  onFocus: () => { },
  outline: false,
  segment: undefined,
  size: "medium",
  submit: false,
  tackons: undefined,
};

export default Button;
