// -----------------------------------------------------------------------------
// Checkbox
// -----------------------------------------------------------------------------

//React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Icon from "components/icon";

//Utilities
import { ClassNameMaker, Tackons } from "utils";

//Styles
import Styles from "./checkbox.module.scss";

/**
 *
 * Checkbox is a styled checkbox for use in forms.
 */
function Checkbox(props) {
  const {
    checked,
    disabled,
    hint,
    id,
    invalid,
    label,
    labelHidden,
    name,
    onChange,
    onFocus,
    onBlur,
    tackons,
    value
  } = props;
  //Variables

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    invalid && Styles["invalid"],
    disabled && Styles["disabled"],
    labelHidden && Styles["labelHidden"]
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  const [isChecked, setCheckedState] = useState(checked);

  useEffect(() => {
    setCheckedState(checked);
  }, [checked]);

  const handleChange = e => {
    const newChecked = e.target.checked;

    setCheckedState(newChecked);
    onChange(e);
  };

  //Output

  return (
    <div className={className} style={style}>
      <input
        className={Styles.input}
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={isChecked}
        disabled={disabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={e => handleChange(e)}
        aria-invalid={invalid}
      />
      <label htmlFor={id} className={Styles.label}>
        <span className={Styles.text}>{label}</span>
      </label>
      {hint && <div className={Styles.hint}>{hint}</div>}
      {invalid && typeof invalid === "string" && (
        <div className={Styles.invalidText} aria-live="polite">
          <Icon name="exclamation-circle" width="16" height="16" decorative />
          {invalid}
        </div>
      )}
    </div>
  );
}

Checkbox.displayName = "Checkbox";

Checkbox.propTypes = {
  /**
   * Toggles the checked state of the checkbox
   */
  checked: PropTypes.bool,
  /**
   * Add disabled attribute to the input
   */
  disabled: PropTypes.bool,
  /**
   * Additional text to support the label
   */
  hint: PropTypes.string,
  /**
   * Unique indentifier that ties the label to the input
   */
  id: PropTypes.string.isRequired,
  /**
   * May be added as a prop to toggle or the invalid state, or given a string to show an error message.
   */
  invalid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Label for the checkbox
   */
  label: PropTypes.string.isRequired,
  /**
   * Visually hide the label (still visible to screenreaders)
   */
  labelHidden: PropTypes.bool,
  /**
   * Used to identify form data after it has been submitted to the server
   */
  name: PropTypes.string,
  /**
   * Callback when changed
   */
  onChange: PropTypes.func,
  /**
   * Callback when focussed
   */
  onFocus: PropTypes.func,
  /**
   * Callback on blur
   */
  onBlur: PropTypes.func,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
  /**
   * Value that is transmitted on submit
   */
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  hint: undefined,
  id: undefined,
  invalid: undefined,
  label: undefined,
  labelHidden: false,
  name: undefined,
  onChange: () => { },
  onFocus: () => { },
  onBlur: () => { },
  tackons: undefined,
  value: undefined
};

export default Checkbox;
