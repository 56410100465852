import React from "react";
import PropTypes from "prop-types";

// Styles
import Styles from "./heading.module.scss";

// Utilities
import { ClassNameMaker, Tackons } from "utils";

export default function Heading(props) {
  const { children, element, id, muted, size, tackons, truncated } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    muted && Styles["muted"],
    size && Styles[size],
    truncated && Styles["truncated"]
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return React.createElement(element ? element : "h3", {
    className: className,
    children: children,
    id: id,
    style: style
  });
}

Heading.propTypes = {
  /**
   * Content to display inside the Heading
   */
  children: PropTypes.node,
  /**
   * Container element to use as the render
   */
  element: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "div", "p"]),
  /**
   * Unique indentifier to identify the element in the DOM
   */
  id: PropTypes.string,
  /**
   * Toggle the text color to a lighter gray.
   */
  muted: PropTypes.bool,
  /**
   * Display size of the text
   */
  size: PropTypes.oneOf([
    "default",
    "small",
    "regular",
    "medium",
    "large",
    "xlarge"
  ]),
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
  /**
   * Make this text single-line. If the line length consumes more than 100% of the container width any overflow will be hidden and replaced with an ellipsis
   */
  truncated: PropTypes.bool
};

Heading.defaultProps = {
  children: undefined,
  element: undefined,
  id: undefined,
  muted: false,
  size: undefined,
  tackons: undefined
};
