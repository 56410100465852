import React, { useCallback, ReactElement, CSSProperties, useRef } from "react";
import { GlyphDot } from "@visx/glyph";
import { GlyphDotProps } from "@visx/glyph/lib/glyphs/GlyphDot";
import { curveLinear, curveStep, curveCardinal } from "@visx/curve";
import { AnimationTrajectory } from "@visx/react-spring/lib/types";
import { TextProps } from "@visx/text/lib/Text";
import {
  buildChartTheme,
  lightTheme,
  darkTheme,
  XYChartTheme,
  EventHandlerParams,
  GlyphProps,
  Margin,
} from "@visx/xychart";
import ParentSize, {
  ParentSizeProps,
} from "@visx/responsive/lib/components/ParentSize";
import { TickFormatter } from "@visx/axis";

import ChartBackground from "./ChartBackground";
import { RenderTooltipParams } from "@visx/xychart/lib/components/Tooltip";
import { ThemeConfig } from "@visx/xychart/lib/theme/buildChartTheme";
import { AxisScaleOutput } from "@visx/axis";
import { ValueOf, ScaleTypeToScaleConfig } from "@visx/scale";

import getAnimatedOrUnanimatedComponents from "../helpers/getAnimatedOrUnanimatedComponents";
import isNumber from "../helpers/isNumber";
import isString from "../helpers/isString";

export type ISizeProps = {
  width: number;
  height: number;
};

type Accessor = (d: { [key: string]: any }) => number | string;

type Scale = (...args: any) => any;

interface IAccessors {
  [key: string]: Accessor;
}

interface IDataItem {
  active?: boolean;
  [key: string]: string | number | boolean;
}

interface IChartPropsAccessors {
  x: IAccessors;
  y: IAccessors;
  [key: string]: IAccessors;
}

interface IChartProps extends ISizeProps {
  /**
   * Indicates whether chart needs to be animated or unanimated
   */
  animated?: boolean;
  /**
   * Map of Datum "getter" functions
   */
  accessors: IChartPropsAccessors;
  /**
   * Animation trajectory
   */
  animationTrajectory: AnimationTrajectory;
  /**
   * If DataContext is not available, XYChart will wrap itself in a DataProvider and set this as the xScale config.
   * @see {@link https://airbnb.io/visx/docs/scale | @visx/scale} for further information.
   */
  xScale?: ValueOf<ScaleTypeToScaleConfig<AxisScaleOutput, any, any>>;
  /**
   * If DataContext is not available, XYChart will wrap itself in a DataProvider and set this as the yScale config.
   * @see {@link https://airbnb.io/visx/docs/scale | @visx/scale} for further information.
   */
  yScale?: ValueOf<ScaleTypeToScaleConfig<AxisScaleOutput, any, any>>;
  /**
   * Sets the curve factory (from @visx/curve or d3-curve) for the line generator
   */
  curveType: "linear" | "cardinal" | "step";
  /**
   * Reduce count of data
   */
  fewerDatum?: number;
  /**
   * Collection of Datum
   */
  data: { [key: string]: string | number }[];
  /**
   * The number of ticks wanted for the grid
   */
  numTicks?: number;
  /**
   * The number of ticks wanted for the xAxis (note this is approximate)
   */
  xAxisNumTicks?: number;
  /**
   * The number of ticks wanted for the yAxis (note this is approximate)
   */
  yAxisNumTicks?: number;
  /**
   * A d3 formatter for the tick text for xAxis;
   */
  xAxisTickFormat?: TickFormatter<Parameters<Scale>[0]> | undefined;
  /**
   * A d3 formatter for the tick text for xAxis;
   */
  yAxisTickFormat?: TickFormatter<Parameters<Scale>[0]> | undefined;
  /**
   * Indicates whether AreaSeries needs to be rendered
   */
  renderAreaSeries: boolean;
  /**
   * Indicates whether "bar" | "group"| "stack" needs to be rendered
   */
  renderBarStackOrGroup: "bar" | "group" | "stack";
  /**
   * Indicates how GlyphSeries will be rendered
   */
  renderGlyphSeries: "none" | "all" | "active";
  /**
   * Indicates whether LineSeries needs to be rendered
   */
  renderLineSeries: boolean;
  /**
   * Determines whether sharedTooltip needs to be used to display Datum for multy curves
   */
  sharedTooltip: boolean;
  /**
   * Grid type
   */
  gridType: "rows" | "columns" | "both" | "none";
  /**
   * Margin to apply around the outside the
   */
  margin: Margin | undefined;
  /**
   * Show Tooltip
   */
  showTooltip: boolean;
  /**
   * Show Horizontal Crosshair
   */
  showHorizontalCrosshair: boolean;
  /**
   * Show Vertical Crosshair
   */
  showVerticalCrosshair: boolean;
  /**
   * Determines wherether should snap Tooltip to DatumX
   */
  snapTooltipToDatumX: boolean;
  /**
   * Determines wherether should snap Tooltip to DatumX
   */
  snapTooltipToDatumY: boolean;
  /**
   * Theme
   */
  theme: XYChartTheme;
  /**
   * xAxis Orientation
   */
  xAxisOrientation: "top" | "bottom";
  /**
   * yAxis Orientation
   */
  yAxisOrientation: "left" | "right";
  /**
   * Determines whether use predefined theme or custom
   */
  themeType: "light" | "dark" | "custom";
  /**
   * Min max height; Ex. Math.min(maxHeight, parentHeight)
   */
  maxHeight?: number;
  /**
   * Background
   */
  background?: React.ReactNode;
  /**
   * yAxis Label
   */
  yAxisLabel?: string;
  /**
   * xAxis Label
   */
  xAxisLabel?: string;
  /**
   * yAxis Label Props
   */
  yAxislabelProps?: Partial<TextProps> | undefined;
  /**
   * xAxis Label Props
   */
  xAxislabelProps?: Partial<TextProps> | undefined;
  /**
   * Determines whether hide | show AxisLine
   */
  hideAxisLine: boolean | undefined;
  /**
   * Determines whether hide | show AxisLine Ticks
   */
  hideTicks: boolean | undefined;
  /**
   * GlyphDot Radius
   */
  glyphDotR?: number;
  /**
   * Active GlyphDot StrokeWidth
   */
  activeGlyphDotStrokeWidth?: number;
  /**
   * strokeWidth
   */
  strokeWidth?: number;
  /**
   * Grid strokeWidth
   */
  gridStrokeWidth?: number;
  /**
   * xAxis strokeWidth
   */
  xAxisStrokeWidth?: number;
  /**
   * yAxis strokeWidth
   */
  yAxisStrokeWidth?: number;
  /**
   * Glyph renderer
   */
  renderGlyph?: (props: GlyphProps<IDataItem>) => ReactElement<any, any>;
  /**
   * Glyph click distance
   */
  glyphClickDistance?: number;
  /**
   * Glyph click handler
   */
  onGlyphClick?(event: EventHandlerParams<IDataItem>): void;
  /**
   * Tooltip renderer
   */
  renderTooltip: (
    params: RenderTooltipParams<object> & {
      accessors: IChartPropsAccessors;
      sharedTooltip: boolean;
    }
  ) => React.ReactNode;
  /**
   * Tooltip styles
   */
  tooltipStyle: CSSProperties | undefined;
  /**
   * Offset the left position of the Tooltip by this margin
   */
  tooltipOffsetLeft?: number | undefined;
  /**
   * Offset the top position of the Tooltip by this margin
   */
  tooltipOffsetTop: number | undefined;
  /**
   * Optional styles for the tooltip point, if visible.
   */
  tooltipGlyphStyle?: React.SVGProps<SVGCircleElement>;
  /**
   * Custom / additional children
   */
  children?: (props: IChartProps) => React.ReactNode;
}

type FCChart<T> = React.FC<T> & {
  ParentSize: React.FC<
    ParentSizeProps & Omit<JSX.IntrinsicElements["div"], keyof ParentSizeProps>
  >;
  buildChartTheme(config: ThemeConfig): XYChartTheme;
  GlyphDot({
    top,
    left,
    className,
    ...restProps
  }: GlyphDotProps &
    Omit<React.SVGProps<SVGCircleElement>, keyof GlyphDotProps>): JSX.Element;
};

/**
 * Chart
 */
const Chart: FCChart<IChartProps> = (props): JSX.Element => {
  const {
    accessors,
    sharedTooltip,
    renderTooltip: _renderTooltip,
    data: _data,
    renderGlyphSeries,
    renderGlyph: _renderGlyph,
    activeGlyphDotStrokeWidth,
    glyphClickDistance,
    onGlyphClick,
  } = props;

  const theme = {
    light: lightTheme,
    dark: darkTheme,
    custom: props.theme,
  }[props.themeType];

  const {
    AreaSeries,
    Axis,
    BarGroup,
    BarSeries,
    BarStack,
    GlyphSeries,
    Grid,
    LineSeries,
    Tooltip,
    XYChart,
  } = getAnimatedOrUnanimatedComponents(props.animated);

  const glyphOutline = theme.gridStyles.stroke;
  const renderGlyph = useCallback(
    ({ size, color, datum, ...other }: GlyphProps<IDataItem>) =>
      _renderGlyph ? (
        _renderGlyph({
          size,
          color,
          datum,
          ...other,
        })
      ) : renderGlyphSeries === "active" ? (
        datum.active ? (
          <GlyphDot
            {...other}
            cy={other.y}
            cx={other.x}
            stroke={color}
            strokeWidth={activeGlyphDotStrokeWidth}
            fill="white"
            r={size}
          />
        ) : null
      ) : (
        <GlyphDot
          {...other}
          stroke={glyphOutline}
          fill={color}
          r={size}
          cy={other.y}
          cx={other.x}
        />
      ),
    [glyphOutline, renderGlyphSeries, activeGlyphDotStrokeWidth, _renderGlyph]
  );

  const renderBarGroup = props.renderBarStackOrGroup === "group";
  const renderBarSeries = props.renderBarStackOrGroup === "bar";
  const renderBarStack = props.renderBarStackOrGroup === "stack";

  const curve =
    (props.curveType === "cardinal" && curveCardinal) ||
    (props.curveType === "step" && curveStep) ||
    curveLinear;

  const [showGridRows, showGridColumns] = {
    rows: [true, false],
    columns: [false, true],
    both: [true, true],
    none: [false, false],
  }[props.gridType];

  const data =
    props.fewerDatum < 0
      ? _data
      : _data.slice(
          0,
          props.fewerDatum > _data.length ? _data.length : props.fewerDatum
        );

  const accessorKeys = Object.keys(accessors.x).sort();

  let glyphClickTimer = useRef<number>();

  const handleGlyphClick = useCallback(
    ({ distanceX, distanceY, ...event }: EventHandlerParams<IDataItem>) => {
      if (distanceX <= glyphClickDistance && distanceY < glyphClickDistance) {
        clearTimeout(glyphClickTimer.current);

        glyphClickTimer.current = window.setTimeout(
          () =>
            onGlyphClick({
              distanceX,
              distanceY,
              ...event,
            }),
          100
        );
      }
    },
    [glyphClickTimer, onGlyphClick, glyphClickDistance]
  );

  const handlePointerUp = useCallback(
    (event: EventHandlerParams<IDataItem>) => {
      handleGlyphClick(event);
    },
    [handleGlyphClick]
  );

  const renderTooltip = useCallback(
    (tooltipProps) => {
      const {
        tooltipData: {
          nearestDatum: { datum },
        },
      } = tooltipProps;
      if (datum.hidden) return null;

      const key = accessorKeys.find((key) => {
        const v = accessors.y[key](datum);

        return isNumber(isString(v) ? +v : v);
      });

      return key
        ? _renderTooltip({
            ...tooltipProps,
            accessors,
            sharedTooltip,
          })
        : null;
    },
    [accessorKeys, accessors, _renderTooltip, sharedTooltip]
  );

  return (
    <XYChart
      margin={props.margin}
      theme={theme}
      xScale={props.xScale}
      yScale={props.yScale}
      height={Math.min(props.maxHeight, props.height)}
      pointerEventsDataKey="nearest"
      onPointerUp={handlePointerUp}
    >
      {props.background}
      <Grid
        key={`grid-${props.animationTrajectory}`} // force animate on update
        rows={showGridRows}
        columns={showGridColumns}
        animationTrajectory={props.animationTrajectory}
        numTicks={props.numTicks}
        strokeWidth={props.gridStrokeWidth}
      />
      {renderBarStack && (
        <BarStack>
          {accessorKeys.map((key) => (
            <BarSeries
              enableEvents={true}
              key={key}
              dataKey={key}
              data={data}
              xAccessor={accessors.x[key]}
              yAccessor={accessors.y[key]}
            />
          ))}
        </BarStack>
      )}
      {renderBarGroup && (
        <BarGroup>
          {accessorKeys.map((key) => (
            <BarSeries
              enableEvents={true}
              key={key}
              dataKey={key}
              data={data}
              xAccessor={accessors.x[key]}
              yAccessor={accessors.y[key]}
            />
          ))}
        </BarGroup>
      )}
      {renderBarSeries && (
        <>
          {accessorKeys.map((key) => (
            <BarSeries
              enableEvents={true}
              key={key}
              dataKey={key}
              data={data}
              xAccessor={accessors.x[key]}
              yAccessor={accessors.y[key]}
            />
          ))}
        </>
      )}
      {props.renderAreaSeries && !renderBarSeries && (
        <>
          {accessorKeys.map((key) => (
            <AreaSeries
              enableEvents={true}
              key={key}
              dataKey={key}
              data={data}
              xAccessor={accessors.x[key]}
              yAccessor={accessors.y[key]}
              fillOpacity={0.4}
              curve={curve}
              strokeWidth={props.strokeWidth}
            />
          ))}
        </>
      )}
      {props.renderLineSeries && !renderBarSeries && (
        <>
          {accessorKeys.map((key) => (
            <LineSeries
              enableEvents={true}
              key={key}
              dataKey={key}
              data={data}
              xAccessor={accessors.x[key]}
              yAccessor={accessors.y[key]}
              curve={curve}
              strokeWidth={props.strokeWidth}
            />
          ))}
        </>
      )}
      {renderGlyphSeries !== "none" && (
        <>
          {accessorKeys.map((key) => (
            <GlyphSeries
              enableEvents={true}
              key={key}
              dataKey={key}
              data={data}
              xAccessor={accessors.x[key]}
              yAccessor={accessors.y[key]}
              renderGlyph={renderGlyph}
              size={props.glyphDotR}
            />
          ))}
        </>
      )}
      {props.children(props)}
      <Axis
        key={`time-axis-${props.animationTrajectory}`}
        label={props.xAxisLabel}
        orientation={props.xAxisOrientation}
        numTicks={props.xAxisNumTicks}
        animationTrajectory={props.animationTrajectory}
        hideAxisLine={props.hideAxisLine}
        hideTicks={props.hideTicks}
        tickFormat={props.xAxisTickFormat}
        strokeWidth={props.xAxisStrokeWidth}
        {...(props.xAxislabelProps
          ? { labelProps: props.xAxislabelProps }
          : {})}
      />
      <Axis
        key={`temp-axis-${props.animationTrajectory}`}
        label={props.yAxisLabel}
        orientation={props.yAxisOrientation}
        numTicks={props.yAxisNumTicks}
        animationTrajectory={props.animationTrajectory}
        hideAxisLine={props.hideAxisLine}
        hideTicks={props.hideTicks}
        tickFormat={props.yAxisTickFormat}
        strokeWidth={props.yAxisStrokeWidth}
        {...(props.yAxislabelProps
          ? { labelProps: props.yAxislabelProps }
          : {})}
      />
      {props.showTooltip && (
        <Tooltip<IDataItem>
          showHorizontalCrosshair={props.showHorizontalCrosshair}
          showVerticalCrosshair={props.showVerticalCrosshair}
          snapTooltipToDatumX={props.snapTooltipToDatumX}
          snapTooltipToDatumY={props.snapTooltipToDatumY}
          showDatumGlyph={
            (props.snapTooltipToDatumX || props.snapTooltipToDatumY) &&
            !renderBarGroup
          }
          glyphStyle={props.tooltipGlyphStyle}
          offsetLeft={props.tooltipOffsetLeft}
          offsetTop={props.tooltipOffsetTop}
          style={props.tooltipStyle}
          showSeriesGlyphs={sharedTooltip && !renderBarGroup}
          renderTooltip={renderTooltip}
        />
      )}
    </XYChart>
  );
};

Chart.defaultProps = {
  animated: true,
  maxHeight: 400,
  theme: lightTheme,
  numTicks: 4,
  xAxisNumTicks: 4,
  yAxisNumTicks: 4,
  data: [],
  background: <ChartBackground />,
  renderTooltip,
  fewerDatum: -1,
  yAxisLabel: "",
  xAxisLabel: "",
  glyphDotR: 8,
  activeGlyphDotStrokeWidth: 3,
  strokeWidth: 4,
  xScale: { type: "band", paddingInner: 0.3 },
  yScale: { type: "linear" },
  children: (_props: IChartProps) => null,
  glyphClickDistance: 4,
  onGlyphClick: (_event: EventHandlerParams<IDataItem>) => void 0,
  tooltipStyle: {
    backgroundColor: "#ffffff",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "12px 16px",
    borderRadius: "16px",
    border: "none",
    boxShadow:
      "0px 1px 12px rgba(0, 0, 0, 0.05), 0px 1px 6px rgba(0, 0, 0, 0.05)",
    // font
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
  },
  tooltipOffsetLeft: 0,
  tooltipOffsetTop: 0,
  tooltipGlyphStyle: { radius: 8, strokeWidth: 4 },
  xAxisTickFormat: (e) => e,
  yAxisTickFormat: (e) => e,
};

Chart.ParentSize = ParentSize;
Chart.buildChartTheme = buildChartTheme;
Chart.GlyphDot = GlyphDot;

Chart.displayName = "Chart";

export default Chart;

function renderTooltip(
  _props: RenderTooltipParams<object> & {
    accessors: IChartPropsAccessors;
    sharedTooltip: boolean;
  }
): JSX.Element {
  return <span>Test Tooltip</span>;
}
