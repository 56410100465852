//React
import React from "react";
import PropTypes from "prop-types";

//Utilities
import { ClassNameMaker, Tackons } from "utils";

//Styles
import Styles from "./badge.module.scss";

function Badge(props) {
  const { appearance, id, label, size, tackons, children } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    appearance && Styles[appearance],
    size && Styles[size],
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  //Output
  return (
    <div className={className} id={id} style={style}>
      {appearance && <span className={Styles.appearance}>{appearance}</span>}
      <span>{label || children}</span>
    </div>
  );
}

Badge.displayName = "Badge";

Badge.propTypes = {
  /**
   * Color to indicate visual state. Appearance is also added as a visually hidden element for screenreaders
   */
  appearance: PropTypes.oneOf([
    "default",
    "info",
    "success",
    "warning",
    "attention",
    "error",
  ]),
  /**
   * Content to display inside the Badge
   */
  children: PropTypes.node,
  /**
   * Unique label to identify this element in the dom
   */
  id: PropTypes.string,
  /**
   * Label to display inside the badge
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
};
Badge.defaultProps = {
  appearance: "default",
  children: undefined,
  id: undefined,
  label: undefined,
  size: "medium",
  tackons: undefined,
};
export default Badge;
