import React from "react";
import { IThumbProps as IRThumbProps } from "react-range/lib/types";

import Styles from "../range.module.scss";

export interface IThumbProps {
  props: IRThumbProps;
  value: number;
  index: number;
  isDragged: boolean;
  showTooltip: boolean;
  tooltipValueFormatter(value: number): number;
}

const Thumb: React.FC<IThumbProps> = ({ props: tProps, ...other }) => (
  <div
    {...tProps}
    className={Styles.thumb}
    key={other.index}
    style={{ ...tProps.style, zIndex: 1 }}
  >
    {other.showTooltip ? (
      <div className={Styles.tooltip}>
        <div className={Styles.tooltipInner}>
          {other.tooltipValueFormatter(other.value)}
        </div>
      </div>
    ) : (
      <></>
    )}
    <div className={Styles.thumbTick} />
    <div className={Styles.thumbTick} />
  </div>
);

Thumb.displayName = "Thumb";

export default Thumb;
