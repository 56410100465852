export function hatefulWayToProgrammaticallyTriggerOnChangeEvent(hiddenSide, newSelection) {
  // this is almost certainly going to break at some point
  const input = hiddenSide.current;
  if (input) {
    const previousValue = input.value;
    // eslint-disable-next-line no-param-reassign
    input.value = newSelection;
    const tracker = input._valueTracker;
    if (tracker) {
      tracker.setValue(previousValue);
    }
    input.dispatchEvent(new Event("change", { bubbles: true }));
  }
}
