// -----------------------------------------------------------------------------
// Bubble
// -----------------------------------------------------------------------------

// React
import React from "react";
import PropTypes from "prop-types";

import Speech from "./components/speech";
import Activity from "./components/activity";

import Icon from "components/icon";
import Avatar from "components/avatar";
import Spinner from "components/spinner";

// Styles
import { ClassNameMaker, Tackons } from "utils";
import Styles from "./bubble.module.scss";

// Utilities

/**
 * Bubble is used in our interface to enclose messages and activities sent as part of the chat experience.
 */
function Bubble(props) {
  const {
    appearance,
    author,
    children,
    datetime,
    id,
    isLast,

    hideMeta,
    orientation,
    status,
    tackons
  } = props;

  // Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    status && Styles[`status-${status}`],
    orientation && Styles[`orient-${orientation}`],
    isLast && Styles.last
  ]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return (
    <div id={id} className={className} style={style}>
      {!hideMeta && (
        <div className={Styles.avatar}>
          <Avatar name={author} appearance={appearance} />
        </div>
      )}
      <div className={Styles.contentWrapper}>
        {!hideMeta && (
          <div className={Styles.meta}>
            <span className={Styles.author}>{author}</span>
            <span className={Styles.time}>{datetime}</span>
          </div>
        )}
        <div className={Styles.content}>
          {status === "failed" && (
            <div className={Styles.failedIcon}>
              <Icon name="exclamation-circle" />
            </div>
          )}
          {children}
        </div>
        {status === "sending" ? (
          <div className={Styles.sending}>
            <div className={Styles.sendingSpinner}>
              <Spinner size="small" color="shade" appearance="wheel" />
            </div>
            Sending &hellip;
          </div>
        ) : status === "failed" ? (
          <div className={Styles.failed}>Failed to send</div>
        ) : null}
      </div>
    </div>
  );
}

Bubble.displayName = "Bubble";
Bubble.Speech = Speech;
Bubble.Activity = Activity;

Bubble.propTypes = {
  /**
   * Appearance is fed to the avatar to select patient/therapist theme
   */
  appearance: PropTypes.oneOf(["patient", "therapist"]).isRequired,
  /**
   * Message author to use for the meta data area, and populate the avatar initials
   */
  author: PropTypes.string.isRequired,
  /**
   * Content to display - this should be one of the bubble sub-components.
   */
  children: PropTypes.node,
  // nChildren(1, childrenOfType("Component")),
  /**
   * Time and date of the message to show in the meta data
   */
  datetime: PropTypes.string.isRequired,
  /**
   * Unique identifier
   */
  id: PropTypes.string,
  /**
   * In a sequence of messages from the same user we round the corner of the last message in the sequence
   */
  isLast: PropTypes.bool,
  /**
   * Hide the author and avatar? In conversations we usually show meta only on the first message from the same party
   * to keep the chat stream relatively condensed
   */
  hideMeta: PropTypes.bool,
  /**
   * Set alignment of the message
   */
  orientation: PropTypes.oneOf(["left", "right"]),
  /**
   * Status is used for both sending and receiving message - "typing" is used to indicate that the other user is active.
   * "sending", "sent", "failed" are used to indicate the transmission status of the current users message
   */
  status: PropTypes.oneOf(["sending", "sent", "failed"]),
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string
};

Bubble.defaultProps = {
  appearance: undefined,
  author: undefined,
  children: undefined,
  datetime: undefined,
  id: undefined,
  isLast: false,
  hideMeta: false,
  orientation: "left",
  status: "sent",
  tackons: undefined
};

export default Bubble;
