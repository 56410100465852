import React from "react";
import PropTypes from "prop-types";

// Styles
import Styles from "./cell.module.scss";

// Utilities
import { ClassNameMaker, StyleMaker } from "utils";

export function Cell(props) {
  const {
    align,
    children,
    density,
    display,
    grow,
    id,
    isFocusable,
    justify,
    maxWidth,
    minWidth,
    shrink,
    singleLine,
    tabIndex = 0,
    tackons,
    title
  } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([
    Styles.container,
    Styles[`density-${density}`],
    Styles[`display-${display}`],
    Styles[`grow-${grow}`],
    Styles[`shrink-${shrink}`],
    Styles[`justify-${justify}`],
    Styles[`align-${align}`]
  ]);

  let style = StyleMaker(tackons, [
    { minWidth: minWidth },
    { maxWidth: maxWidth }
  ]);

  return (
    <div
      className={className}
      style={style}
      id={id}
      role="gridcell"
      tabIndex={isFocusable ? tabIndex : undefined}
    >
      <span
        className={singleLine ? Styles.singleLine : undefined}
        title={title ? title : undefined}
      >
        {children}
      </span>
    </div>
  );
}

Cell.displayName = "Cell";

Cell.propTypes = {
  /**
   * Change the alignment of the text within
   */
  align: PropTypes.oneOf(["top", "middle", "bottom"]),
  /**
   * Content to display inside the cell
   */
  children: PropTypes.node,
  /**
   * How much padding inside the cell. This is usually set at the row level and inherited by all Cells
   */
  density: PropTypes.oneOf(["compact", "default", "comfortable"]),
  /**
   * Only show this cell above the selected breakpoint
   */
  display: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  /**
   * Flex growth factor relative to other columns.  By default all cells
   * will expand to fill the space, but the distribution will vary according
   * to the grow value.
   */
  grow: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
  /**
   * Uniquely identify this element in the DOM
   */
  id: PropTypes.string,
  /*
   * Makes the Cell focusable
   * Will add tabIndex="0" when true.
   */
  isFocusable: PropTypes.bool,
  /**
   * Change the justification of the text within
   */
  justify: PropTypes.oneOf(["left", "center", "right"]),
  /**
   * Constrain the maximum width of the cell. Pass a positive integer to set a maximum width in pixels.
   * Pass a string (e.g. maxWidth="10%") to use other units.
   */
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Set a minimum width for the cell. Pass a positive integer to set a minimum width in pixels.
   * Pass a string (e.g. minWidth="2rem") to use other units.
   */
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Flex shrink factor relative to other columns.  By default all cells
   * will shrink when insufficient space is available, but the distribution will vary
   * according to the shrink value. Use shrink 0 to stop a cell from shrinking.
   *
   */
  shrink: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6]),
  /**
   * Force the contents of the cell to be single line. Any overflow will be
   * hidden by an ellipsis.
   */
  singleLine: PropTypes.bool,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
  /**
   * Supply a title attribute for the cell - useful when the single line
   * attribute causes text to be hidden
   */
  title: PropTypes.string
};

Cell.defaultProps = {
  align: "middle",
  children: undefined,
  density: "default",
  display: undefined,
  grow: undefined,
  id: undefined,
  isFocusable: false,
  justify: "left",
  maxWidth: undefined,
  minWidth: undefined,
  shrink: undefined,
  singleLine: false,
  tackons: undefined,
  title: undefined
};

export default Cell;
