// -----------------------------------------------------------------------------
// Speech
// -----------------------------------------------------------------------------

// React
import React from "react";
import PropTypes from "prop-types";

import Spinner from "components/spinner";

// Styles
import { ClassNameMaker, Tackons } from "utils";
import Styles from "../bubble.module.scss";

function Speech(props) {
  const { children, id, isMe, isTyping, tackons } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([Styles.bubble, isMe && Styles["me"]]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return (
    <div id={id} className={className} style={style}>
      {isTyping ? (
        <Spinner size="small" color={isMe ? "white" : "shade"} />
      ) : (
        <div className={Styles.text}>{children}</div>
      )}
    </div>
  );
}

Speech.displayName = "Speech";
Speech.propTypes = {
  /**
   * Content to display inside the speech bubble
   */
  children: PropTypes.node,
  /**
   * Unique identifier
   */
  id: PropTypes.string,
  /**
   * isMe sets the background colour of the bubble
   */
  isMe: PropTypes.bool,
  /**
   * isTyping renders a spinner in the speech bubble instead of child content
   */
  isTyping: PropTypes.bool,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string
};

Speech.defaultProps = {
  children: undefined,
  id: undefined,
  isMe: false,
  isTyping: false,
  tackons: undefined
};
export default Speech;
