import React from "react";

import Action from "./components/action";
import Section from "./components/section";

import { IActionListActionProps } from "./components/action";
import { IActionListSectionProps } from "./components/section";

//Utilities
import { StyleMaker } from "utils";

interface IActionListProps {
  /**
   * Use children to describe a custom arrangement of items in the ActionList using `<ActionList.Action/>` and `<ActionList.Section/>` as needed.
   */
  children:
    | React.ReactElement<IActionListActionProps>[]
    | React.ReactElement<IActionListActionProps>
    | React.ReactElement<IActionListSectionProps>[]
    | React.ReactElement<IActionListSectionProps>;
  /**
   * Unique label to identify this element in the dom
   */
  id: string;
  /**
   * Array of objects describing each item in the list as an alternative to using children
   *
   */
  items: any;
  /**
   * Constrain the maximum width of the cell. Pass a positive integer to set a maximum width in pixels.
   * Pass a string (e.g. maxWidth="10%") to use other units.
   */
  maxWidth: number | string;
  /**
   * Set a minimum width for the actionlist. Pass a positive integer to set a minimum width in pixels.
   * Pass a string (e.g. minWidth="2rem") to use other units.
   */
  minWidth: number | string;
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: string;
}

/**
 * ActionList is a sequence of items to be displayed as a vertical menu
 */
function ActionList(props: IActionListProps) {
  const { id, children, items, maxWidth, minWidth, tackons } = props;

  // get inline styles for utility styles
  let style = StyleMaker(tackons, [
    { minWidth: minWidth },
    { maxWidth: maxWidth },
  ]);

  return (
    <div id={id} style={style}>
      {items ? (
        items.length > 1 ? (
          items.map((c, i) => {
            return (
              <Action
                key={i}
                ariaLabel={c.ariaLabel}
                destructive={c.destructive}
                external={c.external}
                id={c.id}
                label={c.label}
                icon={c.icon}
                hint={c.hint}
                onAction={c.onAction}
                disabled={c.disabled}
                url={c.url}
                tackons={c.tackons}
              />
            );
          })
        ) : (
          <Action
            ariaLabel={items[0].ariaLabel}
            destructive={items[0].destructive}
            external={items[0].external}
            id={items[0].id}
            label={items[0].label}
            icon={items[0].icon}
            hint={items[0].hint}
            onAction={items[0].onAction}
            disabled={items[0].disabled}
            url={items[0].url}
            tackons={items[0].tackons}
          />
        )
      ) : (
        children
      )}
    </div>
  );
}

ActionList.Action = Action;
ActionList.Section = Section;

ActionList.defaultProps = {
  children: undefined,
  id: undefined,
  items: undefined,
  maxWidth: undefined,
  minWidth: undefined,
  tackons: undefined,
};

export default ActionList;
