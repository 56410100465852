import React from "react";
import { getTrackBackground } from "react-range";
import { ITrackProps as IRTrackProps } from "react-range/lib/types";

import Styles from "../range.module.scss";

export interface ITrackProps {
  props: IRTrackProps;
  children: React.ReactNode;
  isDragged: boolean;
  values: number[];
  min: number;
  max: number;
  colors: string[];
}

const Track: React.FC<ITrackProps> = ({ props: tProps, ...other }) => (
  <div
    className={Styles.trackContainer}
    onMouseDown={tProps.onMouseDown}
    onTouchStart={tProps.onTouchStart}
  >
    <div
      ref={tProps.ref}
      className={Styles.track}
      style={{
        background: getTrackBackground({
          values: other.values,
          colors: other.colors,
          min: other.min,
          max: other.max,
        }),
      }}
    >
      {React.Children.map(other.children, (child: React.ReactElement, index) =>
        React.cloneElement(child, { key: `track-child-${index}` })
      )}
    </div>
  </div>
);

Track.displayName = "Track";

export default Track;
