import React from "react";
import { useHistory } from "react-router-dom";
import Button from "components/button";
import { StackPanel, TextRun, TinyMarkup } from "./components";
import Styles from "./errorPage.module.scss";

const resources = {
    "not-found": {
        title: "We're sorry, the page you are looking for can't be found",
        message: "We may have removed the page from the therapy site or the link you clicked may be old and does not work any more.",
        contactUs: "If you need help or you want to tell us about broken links, [contact us]({contactUsAddress}).",
        returnHomeLabel: "Return home",
        imageUrl: "/img/404.png"
    },
    unauthorized: {
        title: "We're sorry, you don't have access to this page",
        message: "The page you're trying to view has restricted access.",
        contactUs: "If you need help or you want to tell us about broken links, [contact us]({contactUsAddress}).",

        returnHomeLabel: "Return home",
        imageUrl: "/img/403.png",
    }
};

export type ErrorPageProps = {
    /**
     * The type of error page to display. `"not-found"` is usually associated with
     * 404 and `"unauthorized"` with 403. Default value is `"not-found"`.
     */
    readonly error?: "not-found" | "unauthorized";

    /**
     * Custom title for the page, if omitted the default one (chosen accordingly
     * the `error` property value) is used.
     */
    readonly title?: string;

    /**
     * Custom _main_ message for the page, if omitted the default one (chosen accordingly
     * the `error` property value) is used.
     * This text supports a tiny markup: placeholders `{placeholder-name}`
     * and links `[link-name](link-url)`. All the (string) properties of this component
     * could be used as placeholders (defaults are used when unspecified). When the link URL
     * is an e-mail then `"mailto:""` prefix is automatically added.
     */
    readonly message?: string;

    /**
     * Custom _contact us_ message for the page, if omitted the default one is used.
     * Similarly to `message` this property supports a tiny markup with the same
     * syntax and limitations.
     */
    readonly contactUs?: string;

    /**
     * Custom footer for the page.
     * Similarly to `message` this property supports a tiny markup with the same
     * syntax and limitations. Note that footer is positioned after `children`.
     */
    readonly footer?: string;

    /**
     * Label for the _Return home_ link, if omitted then default one is used.
     */
    readonly returnHomeLabel?: string;

    /**
     * URL (should be local) for the _Return home_ link. Default value is `"/"`.
     */
    readonly homeUrl?: string;

    /**
     * URL for the image, if omitted the default one (chosen accordingly
     * the `error` property value) is used.
     */
    readonly imageUrl?: string;

    /**
     * Address for the _Contact us_ link. Default value is `"/en-gb/contact "`.
     */
    readonly contactUsAddress?: string;
};

/**
 * Generic page to display a friendly page for a known HTTP error status code.
 */
const ErrorPage: React.FC<ErrorPageProps> = props => {
    const history = useHistory();

    const title = props.title ?? resources[props.error].title;
    const message = props.message ?? resources[props.error].message;
    const contactUs = props.contactUs ?? resources[props.error].contactUs;
    const returnHomeLabel = props.returnHomeLabel ?? resources[props.error].returnHomeLabel;
    const imageUrl = props.imageUrl ?? resources[props.error].imageUrl;

    return (
        <StackPanel
            className={Styles.container}
            layout={{ medium: "vertical", large: "horizontal" }}
            gutter
        >
            <StackPanel layout="vertical">
                <TextRun variant="page-title">{title}</TextRun>
                <TextRun>
                    <TinyMarkup text={`${message} ${contactUs}`} model={props} />
                </TextRun>
                <Button
                    className={Styles["return-home"]}
                    appearance="primary"
                    onClick={() => history.push(props.homeUrl)}
                >
                    {returnHomeLabel}
                </Button>
                {props.children}
                {props.footer && <TextRun><TinyMarkup text={props.footer} model={props} /></TextRun>}
            </StackPanel>
            <img
                className={Styles.image}
                src={imageUrl}
                alt={title}
                aria-hidden="true"
            />
        </StackPanel>
    );
};

ErrorPage.displayName = "ErrorPage";
ErrorPage.defaultProps = {
    error: "not-found",
    homeUrl: "/",
    contactUsAddress: "/en-gb/contact"
};

export default ErrorPage;