import React from "react";

import Styles from "./menu.module.scss";

import { IMenuItemProps } from "./menuItem";

import { ClassNameMaker, Tackons } from "utils";

interface IMenuProps {
  /**
   * Unique name to identify this element in the DOM
   */
  id?: string;
  /**
   * Contents of the menu
   */
  children?:
    | React.ReactElement<IMenuItemProps>[]
    | React.ReactElement<IMenuItemProps>;
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons?: string;
}

function Menu(props: IMenuProps) {
  const { id, children, tackons } = props;

  const className = ClassNameMaker([Styles.container]);

  const style = tackons && Tackons(tackons);

  return React.createElement(
    "ul",
    {
      className: className,
      style: style,
      id: id,
    },
    children
  );
}

Menu.defaultProps = {
  id: undefined,
  children: undefined,
  tackons: undefined,
};

export default Menu;
