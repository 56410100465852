import {
  // animated
  AnimatedAreaSeries,
  AnimatedAxis,
  AnimatedBarGroup,
  AnimatedBarSeries,
  AnimatedBarStack,
  AnimatedGlyphSeries,
  AnimatedGrid,
  AnimatedLineSeries,

  // not animated
  AreaSeries,
  Axis,
  BarGroup,
  BarSeries,
  BarStack,
  GlyphSeries,
  Grid,
  LineSeries,

  // no animated equivalents
  Tooltip,
  XYChart,
} from "@visx/xychart";

const getAnimatedOrUnanimatedComponents = (animated?: boolean) => ({
  ...(animated
    ? {
        AreaSeries: AnimatedAreaSeries,
        Axis: AnimatedAxis,
        BarGroup: AnimatedBarGroup,
        BarSeries: AnimatedBarSeries,
        BarStack: AnimatedBarStack,
        GlyphSeries: AnimatedGlyphSeries,
        Grid: AnimatedGrid,
        LineSeries: AnimatedLineSeries,
      }
    : {
        AreaSeries,
        Axis,
        BarGroup,
        BarSeries,
        BarStack,
        GlyphSeries,
        Grid,
        LineSeries,
      }),
  Tooltip,
  XYChart,
});

export default getAnimatedOrUnanimatedComponents;
