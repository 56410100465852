import React from "react";
import Styles from "../carousel.module.scss";

export interface ICarouselItemProps {
  children: React.ReactElement;
}

function Item(props: ICarouselItemProps) {
  const { children } = props;
  return <li className={Styles.carouselItem}>{children}</li>;
}

export default Item;
