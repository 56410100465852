// -----------------------------------------------------------------------------
// Carousel
// -----------------------------------------------------------------------------

// React
import React, { useEffect, useRef, useState } from "react";
import Button from "../button";
import Item from "./components/carouselItem";
import Dots from "./components/dots";

import { ICarouselItemProps } from "./components/carouselItem";
// Styles
import Styles from "./carousel.module.scss";
import { Tackons } from "utils";

interface ICarouselProps {
  /** Change arrows style */
  arrowStyle?: "circle";
  /** Add navigation dots on the bottom  */
  dots: boolean;
  /** Expect children to be a list of Carousel.Item components  */
  children:
  | React.ReactElement<ICarouselItemProps>
  | React.ReactElement<ICarouselItemProps>[];
  /** If true hide left arrow if there are no items to scroll left and hide right arrow if there are no items to scroll right */
  hideArrowsOnBoundary: boolean;
  /** Callback when first visible item changes; returns new first visible item index */
  onChange?(value: any): void;
  /** Functional CSS add-ons to override margin, position etc. */
  tackons: string;
}

const Carousel = (props: ICarouselProps) => {
  const {
    arrowStyle,
    dots,
    children,
    hideArrowsOnBoundary,
    onChange,
    tackons,
  } = props;

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  const itemsContainer = useRef();
  const wrapperRef = useRef();
  const [firstVisibleItemIndex, setFirstVisibleItemIndex] = useState(0);

  return (
    <>
      <div className={Styles.carouselContainer} ref={wrapperRef} style={style}>
        <LeftArrow
          firstVisibleItemIndex={firstVisibleItemIndex}
          setFirstVisibleItemIndex={setFirstVisibleItemIndex}
          itemsContainer={itemsContainer}
          wrapperRef={wrapperRef}
          arrowStyle={arrowStyle}
          children={children}
          hideArrowsOnBoundary={hideArrowsOnBoundary}
          onChange={onChange}
        />
        <ul ref={itemsContainer} className={Styles.itemsContainer}>
          {children}
        </ul>
        <RightArrow
          firstVisibleItemIndex={firstVisibleItemIndex}
          setFirstVisibleItemIndex={setFirstVisibleItemIndex}
          itemsContainer={itemsContainer}
          wrapperRef={wrapperRef}
          arrowStyle={arrowStyle}
          children={children}
          hideArrowsOnBoundary={hideArrowsOnBoundary}
          onChange={onChange}
        />
      </div>
      {dots ? (
        <ul className={Styles.dotsContainer}>
          <Dots
            containerRef={itemsContainer}
            selectedIndex={firstVisibleItemIndex}
            onChange={onChange}
            setFirstVisibleItemIndex={setFirstVisibleItemIndex}
          />
        </ul>
      ) : null}
    </>
  );
};

const LeftArrow = ({
  firstVisibleItemIndex,
  setFirstVisibleItemIndex,
  itemsContainer,
  wrapperRef,
  arrowStyle,
  hideArrowsOnBoundary,
  children,
  onChange,
}) => {
  const [hideLeftArrow, setHideLeftArrow] = useState(false);
  const leftArrowClass = `${getArrowClass(arrowStyle)} ${hideLeftArrow ? Styles.hideArrow : Styles.arrow}`;
  const numberOfChildren = itemsContainer.current?.children?.length;

  useEffect(() => {
    if (itemsContainer.current && wrapperRef.current) {
      const items = itemsContainer.current.children;
      const isNothingToScroll =
        items[0].offsetWidth * items.length < wrapperRef.current.offsetWidth;
      const isFirstItenVisible =
        hideArrowsOnBoundary && firstVisibleItemIndex === 0;

      setHideLeftArrow(isNothingToScroll || isFirstItenVisible);
    }
  }, [itemsContainer, numberOfChildren, wrapperRef, firstVisibleItemIndex, hideArrowsOnBoundary, children]);

  return (
    <div className={leftArrowClass}>
      <Button
        icon="caret-left"
        onClick={() =>
          scrollBackward(
            itemsContainer.current,
            firstVisibleItemIndex,
            setFirstVisibleItemIndex,
            onChange
          )
        }
      ></Button>
    </div>
  );
};

const RightArrow = ({
  firstVisibleItemIndex,
  setFirstVisibleItemIndex,
  itemsContainer,
  wrapperRef,
  arrowStyle,
  hideArrowsOnBoundary,
  children,
  onChange,
}) => {
  const [hideRightArrow, setHideRightArrow] = useState(false);
  const rightArrowClass = `${getArrowClass(arrowStyle)} ${hideRightArrow ? Styles.hideArrow : Styles.arrow}`;
  const numberOfChildren = itemsContainer.current?.children?.length;

  useEffect(() => {
    if (itemsContainer.current && wrapperRef.current) {
      const items = itemsContainer.current.children;
      const restItemsWidth =
        (items.length - firstVisibleItemIndex) * items[0].offsetWidth;
      const isNothingToScroll =
        items[0].offsetWidth * items.length < wrapperRef.current.offsetWidth;
      const isLastItemVisible =
        hideArrowsOnBoundary &&
        restItemsWidth < itemsContainer.current.offsetWidth;

      setHideRightArrow(isNothingToScroll || isLastItemVisible);
    }
  }, [itemsContainer, numberOfChildren, wrapperRef, firstVisibleItemIndex, hideArrowsOnBoundary, children]);

  return (
    <div className={rightArrowClass}>
      <Button
        icon="caret-right"
        onClick={() =>
          scrollForward(
            itemsContainer.current,
            firstVisibleItemIndex,
            setFirstVisibleItemIndex,
            onChange
          )
        }
      ></Button>
    </div>
  );
};

function scrollForward(
  container,
  firstVisibleItemIndex,
  setFirstVisibleItemIndex,
  onChange
) {
  scroll(
    container,
    firstVisibleItemIndex,
    setFirstVisibleItemIndex,
    onChange,
    true
  );
}

function scrollBackward(
  container,
  firstVisibleItemIndex,
  setFirstVisibleItemIndex,
  onChange
) {
  scroll(
    container,
    firstVisibleItemIndex,
    setFirstVisibleItemIndex,
    onChange,
    false
  );
}

function scroll(
  container,
  firstVisibleItemIndex,
  setFirstVisibleItemIndex,
  onChange,
  isForward
) {
  const items = container.children;
  const itemWidth = items[0].offsetWidth;
  const visibleItemsCount = Math.floor(container.offsetWidth / itemWidth);

  let newFirstVisibleItemIndex;
  let leftPosition;

  if (isForward) {
    newFirstVisibleItemIndex =
      firstVisibleItemIndex + visibleItemsCount < items.length
        ? firstVisibleItemIndex + visibleItemsCount
        : firstVisibleItemIndex;

    leftPosition = (firstVisibleItemIndex + visibleItemsCount) * itemWidth;
  } else {
    newFirstVisibleItemIndex =
      firstVisibleItemIndex - visibleItemsCount > 0
        ? firstVisibleItemIndex - visibleItemsCount
        : 0;

    leftPosition = (firstVisibleItemIndex - visibleItemsCount) * itemWidth;
  }

  if (firstVisibleItemIndex !== newFirstVisibleItemIndex) {
    container.scrollTo({
      left: leftPosition,
      behavior: "smooth",
    });
    onChange(newFirstVisibleItemIndex);
    setFirstVisibleItemIndex(newFirstVisibleItemIndex);
  }
}

function getArrowClass(style) {
  if (style === "circle") {
    return Styles.arrowCircle;
  }
}

Carousel.Item = Item;

Carousel.displayName = "Carousel";

Carousel.defaultProps = {
  arrowStyle: undefined,
  children: undefined,
  hideArrowsOnBoundary: false,
  dots: false,
  tackons: undefined,
  onChange: () => { },
};

export default Carousel;
