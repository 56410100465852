import React from "react";
import PropTypes from "prop-types";
import IconPaths from "./iconPaths.generated";

//Styles
import Styles from "./icon.module.scss";

const IconsEnum = Object.keys(IconPaths);

function Icon(props) {
  const { accessibilityTitle, decorative, name, width, height } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      role="img"
      width={width}
      height={height}
      aria-labelledby={decorative ? null : `${name}-title`}
      aria-hidden={decorative}
      className={Styles.icon}
    >
      {!decorative && <title id={`${name}-title`}>{accessibilityTitle}</title>}
      {IconPaths[name].map((path, i) => (
        <path fill="currentColor" fillRule="evenodd" d={path} key={i} />
      ))}
    </svg>
  );
}

Icon.propTypes = {
  /**
   * Icons must have an accessibility title for screen-readers if they have visual meaning that isn't described by other
   * elements (e.g. an adjacent label). If this is the cas pass the "decorative" prop to hide the icon from screen-readers/
   */
  accessibilityTitle: (props, propName, componentName) => {
    if (!props.decorative && !props.accessibilityTitle) {
      return new Error(
        `Either accessibilityTitle must be supplied or a prop of 'decorative' must be set in ${componentName}`
      );
    }
  },
  /**
   * Set to true if the icon is visual decoration only and so should be hidden from screen readers.
   */
  decorative: PropTypes.bool,
  /**
   * Icon name
   */
  name: PropTypes.oneOf(IconsEnum).isRequired,
  /**
   * Width for the svg element
   */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * Height for the svg element
   */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Icon.defaultProps = {
  accessibilityTitle: undefined,
  decorative: false,
  name: undefined,
  width: "24px",
  height: "24px",
};
export default Icon;
