// -----------------------------------------------------------------------------
// Activity
// -----------------------------------------------------------------------------

// React
import React from "react";
import PropTypes from "prop-types";

import Icon from "components/icon";
import Button from "components/button";
import ButtonGroup from "components/buttonGroup";

// Styles
import { ClassNameMaker, Tackons } from "utils";
import Styles from "../bubble.module.scss";

function Activity(props) {
  const {
    actions,
    children,
    endedTime,
    id,
    startedTime,
    tackons,
    title
  } = props;

  //Allows utility classes to be applied
  const className = ClassNameMaker([Styles.bubble, Styles.activity]);

  // get inline styles for utility styles
  const style = tackons && Tackons(tackons);

  return (
    <div id={id} className={className} style={style}>
      <div className={Styles.bubbleType}>
        <Icon
          name="worksheet"
          width="16"
          height="16"
          accessibilityTitle="worksheet icon"
        />
        <span className={Styles.bubbleTitle}>Activity</span>
      </div>
      <div className={Styles.activityTitle}>{title}</div>
      {(startedTime || endedTime) && (
        <div className={Styles.activityTimes}>
          {startedTime && (
            <span className={Styles.activityStart}>Started {startedTime}</span>
          )}
          {endedTime && (
            <span className={Styles.activityEnd}>Ended {endedTime}</span>
          )}
        </div>
      )}
      {children && <div className={Styles.activityBody}>{children}</div>}
      {actions && (
        <div className={Styles.activityFooter}>
          <ButtonGroup>
            {actions.map((a, i) => {
              return (
                <Button
                  key={i}
                  id={a.id}
                  appearance={a.appearance}
                  size="small"
                  onClick={() => a.onClick()}
                >
                  {a.label}
                </Button>
              );
            })}
          </ButtonGroup>
        </div>
      )}
    </div>
  );
}

Activity.displayName = "Activity";

Activity.propTypes = {
  /**
   * Buttons displayed at the bottom of the activity bubble.
   */
  actions: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      appearance: PropTypes.string,
      id: PropTypes.string,
      onClick: PropTypes.func.isRequired
    })
  ),
  /**
   * Content to display in the body of the bubble
   */
  children: PropTypes.node,
  /**
   * End time of the activity
   */
  endedTime: PropTypes.string,
  /**
   * Unique identifier
   */
  id: PropTypes.string,
  /**
   * Start time of the activity
   */
  startedTime: PropTypes.string,
  /**
   * Functional CSS add-ons to override margin, position etc.
   */
  tackons: PropTypes.string,
  /**
   * Name of the activity
   */
  title: PropTypes.string
};

Activity.defaultProps = {
  actions: undefined,
  children: undefined,
  endedTime: undefined,
  id: undefined,
  startedTime: undefined,
  tackons: undefined,
  title: undefined
};
export default Activity;
